import React, {useEffect} from 'react';
import axios from "axios";
import BASE_URL from "./helpers/url";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import "./styles.scss";
import {useSnackbar} from "notistack";

import LogIn from "./pages/LogIn/LogIn";
import Authenticated from "./components/Authenticated";

import {checkTokenExpiration} from "./helpers/token";
import CloseIcon from "@material-ui/icons/Close";
import {IconButton} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {refreshToken} from "./helpers/api";

export default function App() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // {headers: {Authorization: `Bearer ${checkTokenExpiration()}`}}
  // axios.defaults.headers.common["Authorization"] = `Bearer ${checkTokenExpiration()}`;
  axios.defaults.baseURL = BASE_URL;

  //Add a request interceptor
  axios.interceptors.request.use((config) => {
    config.headers.common['Authorization'] = `Bearer ${checkTokenExpiration()}`;
    return config;
  }, function (error) {
    enqueueSnackbar(error?.response?.data || error.message || error,{ variant: 'error', action: key => (
        <IconButton key="close" color="inherit" onClick={() => closeSnackbar(key)}><CloseIcon /> </IconButton>
      ) });
    return Promise.reject(error);
  });
// Add a response interceptor
  axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, (error) => {
    enqueueSnackbar(error?.response?.data || error.message || error,{ variant: 'error', action: key => (
        <IconButton key="close" color="inherit" onClick={() => closeSnackbar(key)}><CloseIcon /> </IconButton>
      ) });
    return Promise.reject(error);
  });

  useEffect(()=>{
    if (checkTokenExpiration() === "expired") {
      localStorage.removeItem('token');
    }
    let timer = setInterval(() => {
      if ( checkTokenExpiration() !== null || checkTokenExpiration() !== "expired" ) {
        dispatch(refreshToken());
      }
    }, 800000);
    return () => {
      clearInterval(timer);
    }
  });

  useEffect(() => {
    if ( checkTokenExpiration() !== null ) dispatch(refreshToken());
  }, [dispatch]);


  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" render={(props) => <LogIn {...props} />}/>
        <Route path="/" render={(props) => (checkTokenExpiration()=== null || checkTokenExpiration()==="expired") ? <Redirect to="/login" /> : <Authenticated {...props} />}/>
      </Switch>
    </BrowserRouter>
  );
}