import React, { useState } from "react";
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useSelector} from "react-redux";

import Title from "../../components/Title";
import Table from "../../components/Table/Table";

const useStyles = makeStyles(() => ({
  fabProgress: {
    margin: "20% 50%",
  },
}));

const headCells = [
  {id: 'name', label: 'Name', page: 'emails'},
  {id: 'status', label: 'Status'},
  {id: 'updated_at', label: 'Zuletzt bearbeitet'},
];

export default function Emails() {
  const { templates, isFetchingTemplates } = useSelector(state => state.templates);

  const classes = useStyles();

  const [paginationInfo, setPaginationInfo] = useState({current_page: 1, per_page: 10, total: templates.length});

  return (
    <>
      <Title>E-Mail Vorlagen</Title>

      {isFetchingTemplates
        ? <CircularProgress size={68} className={classes.fabProgress}/>
        : <Table
            headCells={headCells}
            tableElements={templates}
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
          />
      }
    </>
  );
}
