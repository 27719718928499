import React from "react";

import {
  // Checkbox,
  // TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
//import {makeStyles} from "@material-ui/core/styles";

// const useStyles = makeStyles(theme => ({
// //   visuallyHidden: {
// //     border: 0,
// //     clip: 'rect(0 0 0 0)',
// //     height: 1,
// //     margin: -1,
// //     overflow: 'hidden',
// //     padding: 0,
// //     position: 'absolute',
// //     top: 20,
// //     width: 1,
// //   },
// // }));

export default function EnhancedTableHead(props) {
  //const classes = useStyles();
  const {
    // onSelectAllClick,
    // numSelected, productCount, onRequestSort,
    order, orderBy, headCells } = props;
  // const createSortHandler = property => event => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    indeterminate={numSelected > 0 && numSelected < productCount}*/}
        {/*    checked={numSelected === productCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{ 'aria-label': 'select all desserts' }}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/*<TableSortLabel*/}
            {/*  active={orderBy === headCell.id}*/}
            {/*  direction={order}*/}
            {/*  onClick={createSortHandler(headCell.id)}*/}
            {/*>*/}
            {/*  {headCell.label}*/}
            {/*  {orderBy === headCell.id ? (*/}
            {/*    <span className={classes.visuallyHidden}>*/}
            {/*      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
            {/*    </span>*/}
            {/*  ) : null}*/}
            {/*</TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}