import { ActionType } from '../actionTypes';

const initialState = {
  orders: [],
  isFetchingOrders: false,
  errorOrders: false,
  errorMessageOrders: '',
};

export default function getOrdersReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_ORDERS_START:
      return {...state, isFetchingOrders: true};
    case ActionType.GET_ORDERS_SUCCESS:
      return {...state, orders: action.data, isFetchingOrders: false };
    case ActionType.GET_ORDERS_FAILURE:
      return {...state, errorOrders: action.error, errorMessageOrders: action.errorMessage, isFetchingOrders: false };

    default:
      return state;
  }
}