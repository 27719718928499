import React, {useState} from 'react';

import {FormControl, TextField, Button, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {confirmNewPassword, resetPassword} from "../../helpers/api";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
    width: 364,
    [theme.breakpoints.down('xs')]: {
      width:"100%",
    },
  },
  input: {
    width: "100%",
    minWidth: 240,
  }
}));

const ForgotPasswordForm = ({location, history}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [email, setEmail] = useState("s.kalinichenko@dinamicka.com");
  const [newPass, setNewPass] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(email, enqueueSnackbar, closeSnackbar, setIsFetching);
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    const resetToken = location.search.split("=")[1];
    confirmNewPassword(newPass, resetToken, history, setIsFetching);
  };

  return (
    <Paper className={classes.paper}>
      {location.search
        ? <FormControl style={{width:"100%"}} component="form" onSubmit={handleSubmitNewPassword} >
          <TextField
            fullWidth
            className={classes.input}
            variant="outlined"
            label="new Passwort"
            type="text"
            value={newPass}
            onChange={(e)=>setNewPass(e.target.value)}
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" style={{marginTop: 16, marginBottom: 16}} disabled={isFetching} >Submit</Button>
        </FormControl>

        : <FormControl style={{width:"100%"}} component="form" onSubmit={handleSubmit} >
          <TextField
            fullWidth
            className={classes.input}
            variant="outlined"
            label="Email"
            type="email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" style={{marginTop: 16, marginBottom: 16}} disabled={isFetching} >Reset Passwort</Button>
        </FormControl>
      }
    </Paper>
  );
};

export default ForgotPasswordForm;