import { ActionType } from '../actionTypes';

const initialState = {
  products: {},
  isFetchingProducts: false,
  errorProducts: false,
  errorMessageProducts: '',
};

export default function getProductReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_PRODUCTS_START:
      return {...state, isFetchingProducts: true};
    case ActionType.GET_PRODUCTS_SUCCESS:
      return {...state, products: action.data, isFetchingProducts: false };
    case ActionType.GET_PRODUCTS_FAILURE:
      return {...state, errorProducts: action.error, errorMessageProducts: action.errorMessage, isFetchingProducts: false };

    default:
      return state;
  }
}