import { ActionType } from '../actionTypes';
import {getHelper} from "./getHelper";

export function getForstamtsStart() {
  return {
    type: ActionType.GET_FORSTAMTS_START,
  }
}
export function getForstamtsSuccess(data) {
  return {
    type: ActionType.GET_FORSTAMTS_SUCCESS,
    data
  }
}
export function getForstamtsFailure(error, errorMessage) {
  return {
    type: ActionType.GET_FORSTAMTS_FAILURE,
    error,
    errorMessage
  }
}

export const getForstamts = () => {
  return dispatch => {
    getHelper('/api/forstamts', getForstamtsStart, getForstamtsSuccess, getForstamtsFailure, dispatch);
  }
};