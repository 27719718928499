import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Title from "../../components/Title";
import ProductLayout from "../../components/ProductLayout";

import {useDispatch} from "react-redux";
import {getCurrentProduct, productUpdate} from "../../helpers/api";

export default function Product({match: {params}}) {
  const dispatch = useDispatch();
  let history = useHistory();

  const [currentRadioForstamt, setCurrentRadioForstamt] = useState({});
  const [currentRevier, setCurrentRevier] = useState({});
  const [currentWoodType, setCurrentWoodType] = useState({});
  const [bereitstellung, setBereitstellung] = useState({});
  const [inputHolzlange, setInputHolzlange] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [inputPreis, setInputPreis] = useState("");

  useEffect(() => {
    getCurrentProduct(params.id, setCurrentRadioForstamt, setCurrentRevier, setCurrentWoodType, setBereitstellung, setInputHolzlange, setInputAmount, setInputPreis);
  },[params.id]);

  const handleChangeProduct = () => {
    productUpdate(params.id, currentWoodType, bereitstellung, inputHolzlange, inputPreis, inputAmount, dispatch);

    history.push("/alle-produkte")
  };

  return (
    <>
      <Title>Artikel #{params.id}</Title>
      <ProductLayout
        currentRadioForstamt={currentRadioForstamt}
        setCurrentRadioForstamt={setCurrentRadioForstamt}
        currentRevier={currentRevier}
        currentWoodType={currentWoodType}
        bereitstellung={bereitstellung}
        inputHolzlange={inputHolzlange}
        inputAmount={inputAmount}
        inputPreis={inputPreis}
        setCurrentRevier={setCurrentRevier}
        setCurrentWoodType={setCurrentWoodType}
        setBereitstellung={setBereitstellung}
        setInputHolzlange={setInputHolzlange}
        setInputAmount={setInputAmount}
        setInputPreis={setInputPreis}
        handleSubmit={handleChangeProduct}
        buttonText="Speichern"
      />
    </>
  );
}
