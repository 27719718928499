import React, {useEffect, useState} from "react";
import axios from "axios";
import { SketchPicker } from 'react-color'

import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Popover,
  CircularProgress
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useDispatch, useSelector} from "react-redux";
import {getReviersByForstamt} from "../../redux/actions/getReviersByForstamtAction";

import Title from "../../components/Title";
import OrderStatusItem from "../../components/OrderStatusItem";
import {getCurrentForstamt, statusCreate, woodTypesCreate, forstamtUpdate} from "../../helpers/api";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
  },
  textField: {
    width:"50%",
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      width:"80%",
    },
  },
  border: { borderRadius:4, border: "1px solid #c4c4c4" },
  color: {
    width: 35,
    height: 23,
    borderRadius: 4,
  },
  fabProgress: {
    margin: "10% 45%",
  },
}));

export default function Settings() {
  const { forstamts } = useSelector(state => state.forstamts);
  const { statuses, isFetchingStatuses } = useSelector(state => state.statuses);
  const { woodTypes } = useSelector(state => state.woodTypes);
  const { reviersByForstamt } = useSelector(state => state.reviersByForstamt);
  const { userLogIn, isFetchingLogIn } = useSelector(state => state.login);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [currentForstamt, setCurrentForstamt] = useState({name: "",email: ""});
  const [currentRevier, setCurrentRevier] = useState([]);
  const [currentRadioForstamt, setCurrentRadioForstamt] = useState([]);
  //const [newRevier, setNewRevier] = useState("");
  const [newWoodType, setNewWoodType] = useState("");
  const [newStatusText, setNewStatusText] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState("#000");

  useEffect(() => {
    if (forstamts.length > 0) setCurrentRadioForstamt(forstamts[0])
  }, [forstamts]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (userLogIn.role !== "revier" && !!currentRadioForstamt.id) {
      getCurrentForstamt({ cancelToken: source.token }, currentRadioForstamt.id, setCurrentForstamt);
    }
    return () => {
      source.cancel();
    }
  }, [currentRadioForstamt]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!isFetchingLogIn && currentRadioForstamt.length !== 0){
      dispatch(getReviersByForstamt(currentRadioForstamt.id, { cancelToken: source.token }));
    }
    return () => {
      source.cancel();
    }
  }, [currentRadioForstamt, isFetchingLogIn, dispatch]);

  const handleCheckboxRevier = (id) => {
    const currRevier = reviersByForstamt.find(el => el.id === id);
    setCurrentRevier(currRevier);
  };
  const handleCheckboxForstamt = (item) => {
    setCurrentRadioForstamt( item );
  };
  const handleChangeColor = (color) => {
    setColor( color.hex );
  };
  const handleClickColor = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseColor = () => {
    setAnchorEl(null);
  };

  const createNewStatus = (e) => {
    e.preventDefault();
    statusCreate(newStatusText, color, dispatch, setNewStatusText);
  };

  // const createRevier = (e) => {
  //   e.preventDefault();
  //   axios.post(BASE_URL + `/reviers/create`,
  //     {
  //       "forstamt_id": 1,
  //       "name": newRevier
  //     },
  //     )
  //     .then((res) => {
  //       dispatch(getReviersByForstamt(1));
  //       setCurrentRevier(res.data);
  //       setNewRevier("");
  //     })
  //     .catch((error) => {
  //       setErrorMessage(error?.response?.data || error.message);
  //       setOnError(true);
  //     });
  // };
  const createWoodTypes = (e) => {
    e.preventDefault();
    woodTypesCreate(currentRevier, newWoodType, setCurrentRevier, setNewWoodType, dispatch);
  };
  const saveForstamt = (e) => {
    e.preventDefault();
    forstamtUpdate(currentForstamt.id, currentForstamt, setCurrentForstamt);
  };

  const openColor = !!anchorEl;
  const showWoodTipesbyReviersID = woodTypes.filter(el => el["revier_id"] === currentRevier.id);

  return (
    <>
      {isFetchingStatuses
        ? (<CircularProgress size={68} className={classes.fabProgress}/>)
        : (<>
            <Title >Einstellungen</Title>

            {userLogIn.role !== "revier"
              && <Paper className={classes.root}>
                  <Grid container spacing={3} >
                    <Grid container item xs={12} alignItems="center" >
                      <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Forstamt</Typography>
                      <Typography variant="subtitle1" component="p" gutterBottom>{currentForstamt.name}</Typography>
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                      <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Email</Typography>
                      <form onSubmit={saveForstamt} style={{flex: 1}} >
                        <TextField
                          className={classes.textField}
                          type="email"
                          variant="outlined"
                          value={currentForstamt.email}
                          onChange={(e)=>setCurrentForstamt({...currentForstamt, email: e.target.value.slice(0, 255)})}
                        />
                        <Button type="submit" variant="contained" color="primary" >Einreichen</Button>
                      </form>
                    </Grid>
                  </Grid>
                </Paper>}

            <Paper className={classes.root}>
              <Grid container >
                <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Forstamt</Typography>
                <Grid container item xs={12} md={9} alignItems="center" >
                  <RadioGroup name="forstamt" style={{width: "100%"}}>
                    <Grid container spacing={3} alignItems="center" className={classes.border}>
                      {forstamts.length > 0 && forstamts.map((el) => {
                        return (
                          <Grid item key={el.id} xs={12} sm={6} lg={4} xl={3}>
                            <FormControlLabel
                              control={<Radio color="primary"/>}
                              label={el.name}
                              labelPlacement="end"
                              checked={el.name === currentRadioForstamt.name}
                              onChange={() => handleCheckboxForstamt(el)}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid container style={{marginTop: 50}} >
                <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Revier</Typography>
                <Grid container item xs={12} md={9} alignItems="center" >
                  <RadioGroup name="revier" style={{width: "100%"}}>
                    {reviersByForstamt.length > 0 &&
                      <Grid container spacing={3} alignItems="center" className={classes.border}>
                        {reviersByForstamt.map((el) => {
                          return (
                            <Grid item key={el.id} xs={12} sm={6} lg={4} xl={3}>
                              <FormControlLabel
                                control={<Radio color="primary"/>}
                                label={el.name}
                                labelPlacement="end"
                                checked={el.name === currentRevier.name}
                                onChange={() => handleCheckboxRevier(el.id)}
                              />
                            </Grid>
                          );
                        })}
                        {/*<Grid item xs={12} >*/}
                        {/*  <form onSubmit={createRevier} >*/}
                        {/*    <TextField*/}
                        {/*      label="Neues hinzufügen Revier..."*/}
                        {/*      className={classes.textField}*/}
                        {/*      type="text"*/}
                        {/*      value={newRevier}*/}
                        {/*      variant="outlined"*/}
                        {/*      onChange={(e)=>setNewRevier(e.target.value.slice(0, 20))}*/}
                        {/*    />*/}
                        {/*    <Button type="submit" variant="outlined" color="primary" >Hinzufügen</Button>*/}
                        {/*  </form>*/}
                        {/*</Grid>*/}
                      </Grid>
                    }
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid container style={{marginTop: 50}} >
                <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Holzart</Typography>
                <Grid container item xs={12} md={9} alignItems="center" >
                  <Grid spacing={3} container alignItems="center" className={classes.border} >
                    {showWoodTipesbyReviersID.length > 0 && showWoodTipesbyReviersID.map((el) => {
                      return (
                        <Grid item key={el.id} xs={12} sm={6} lg={4} xl={3}>
                          <Typography style={{fontSize:18}} variant="inherit" component="p" paragraph>{el.name}</Typography>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} >
                      <form onSubmit={createWoodTypes} >
                        <TextField
                          label="Neue Holzart hinzufüge..."
                          className={classes.textField}
                          type="text"
                          value={newWoodType}
                          variant="outlined"
                          onChange={(e)=>setNewWoodType(e.target.value.slice(0, 255))}
                        />
                        <Button type="submit" variant="outlined" color="primary" >Hinzufügen</Button>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            {userLogIn.role !== "revier" &&
              <Paper className={classes.root}>
                <Grid container >
                  <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Bestellstatus</Typography>
                  <Grid container item xs={12} md={9} alignItems="center" >
                    <RadioGroup name="storage" style={{width: "100%"}}>
                      <Grid container spacing={3} alignItems="center" className={classes.border}>
                        {statuses.length > 0 && statuses.map((el) => {
                          return <OrderStatusItem key={el.id} el={el} />
                        })}
                        <Grid item xs={12} >
                          <form onSubmit={createNewStatus} >
                            <TextField
                              label="Bestellstatus hinzufüge..."
                              className={classes.textField}
                              type="text"
                              value={newStatusText}
                              variant="outlined"
                              onChange={(e)=>setNewStatusText(e.target.value.slice(0, 255))}
                            />
                            <Button variant="outlined" style={{marginRight: 15}} onClick={handleClickColor}>
                              <div className={classes.color} style={{background: `${color}`,}}/>
                            </Button>
                            <Popover
                              onClose={handleCloseColor}
                              open={openColor}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <SketchPicker disableAlpha color={color} onChange={handleChangeColor} />
                            </Popover>
                            <Button type="submit" variant="outlined" color="primary" >Hinzufügen</Button>
                          </form>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Paper>
            }
          </>)
      }
    </>
  );
}
