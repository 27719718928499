import React, { useEffect, useState, useMemo } from "react";
import BASE_URL from "../../helpers/url";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

import Title from "../../components/Title";
import Table from "../../components/Table/Table";
import FilterSelect from "../../components/FilterSelect";

import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../redux/actions/getOrdersAction";
import {
  setSelectedRevier,
  setSelectedStatus,
  setSelectedBereitstellung,
  setSelectedHolzart,
  setSelectedCustomer,
} from "../../redux/actions/setOrdersFiltersAction";
import { orderDelete, orderFilter, ordersSearch } from "../../helpers/api";
import { checkTokenExpiration } from "../../helpers/token";

const headCells = [
  { id: "name", label: "Artikel", page: "bestellungen" },
  { id: "customer", label: "Kundenname", page: "kunden" },
  { id: "revier", label: "Revier" },
  { id: "date", label: "Datum" },
  { id: "status", label: "Bestellstatus" },
  { id: "price_per_fm", label: "Preis/FM" },
  { id: "total_price", label: "Gesamtpreis" },
  { id: "nur_abtransport", label: "Nur Abtransport" },
  { id: "amount", label: "Menge" },
];
const headCellsForAdmin = [
  { id: "name", label: "Artikel", page: "bestellungen" },
  { id: "customer", label: "Kundenname", page: "kunden" },
  { id: "forstamt", label: "Forstamt" },
  { id: "revier", label: "Revier" },
  { id: "date", label: "Datum" },
  { id: "status", label: "Bestellstatus" },
  { id: "price_per_fm", label: "Preis/FM" },
  { id: "total_price", label: "Gesamtpreis" },
  { id: "nur_abtransport", label: "Nur Abtransport" },
  { id: "amount", label: "Menge" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  side: {
    marginTop: 50,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginRight: 10,
    width: 350,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: blueGrey["50"],
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  breadcrumbs: {
    padding: theme.spacing(2, 4),
    backgroundColor: "#fff",
  },
  title: {
    display: "inline-flex",
  },
  link: {
    marginLeft: theme.spacing(4),
    textDecoration: "none",
  },
  fabProgress: {
    margin: "20% 45%",
  },
}));

export default function Orders() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { userLogIn } = useSelector((state) => state.login);
  const {
    selectedRevier,
    selectedStatus,
    selectedBereitstellung,
    selectedHolzart,
    selectedCustomer,
  } = useSelector((state) => state.ordersFilters);
  const { orders, isFetchingOrders } = useSelector((state) => state.orders);

  const [paginationInfo, setPaginationInfo] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [ordersArr, setOrdersArr] = useState([]);
  const [ordersData, setOrdersData] = useState({});
  const [currentOrder, setCurrentOrder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [formattedRevier, setFormattedRevier] = useState([]);
  const [formattedStatus, setFormattedStatus] = useState([]);
  const [formattedBereitstellung, setFormattedBereitstellung] = useState([]);
  const [formattedHolzart, setFormattedHolzart] = useState([]);
  const [formattedCustomers, setFormattedCustomers] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (userLogIn.id !== undefined) {
      orderFilter(
        selectedStatus,
        selectedRevier,
        selectedCustomer,
        selectedHolzart,
        selectedBereitstellung,
        setOrdersArr,
        setOrdersData,
        setLoading,
        paginationInfo.current_page,
        paginationInfo.per_page
      );
    }
  }, [
    userLogIn.id,
    paginationInfo.current_page,
    paginationInfo.per_page,
    dispatch,
  ]);

  const memoOrders = useMemo(
    () =>
      userLogIn.id !== undefined && Object.keys(ordersData).length
        ? ordersData
        : null,
    [userLogIn.id, ordersData]
  );

  useEffect(() => {
    if (userLogIn.id !== undefined && memoOrders) {
      if (Object.keys(memoOrders).length > 0) {
        setOrdersArr(memoOrders.data);
        console.log("memoOrders", memoOrders, paginationInfo);
        if (
          memoOrders.current_page !== paginationInfo.current_page ||
          memoOrders.per_page !== paginationInfo.per_page ||
          memoOrders.total !== paginationInfo.total
        ) {
          setPaginationInfo({
            current_page: memoOrders.current_page,
            per_page: memoOrders.per_page,
            total: memoOrders.total,
          });
        }
      }
    }
  }, [userLogIn.id, memoOrders]);

  useEffect(() => {
    if (ordersArr && memoOrders) {
      const { revier } = memoOrders.filteOptions.reviers.reduce(
        (acc, el) => {
          if (
            !memoOrders.filteOptions.reviers ||
            memoOrders.filteOptions.reviers.length === 0
          )
            return false;

          return { revier: [...acc.revier, el] };
        },
        { revier: [] }
      );

      const { status } = memoOrders.filteOptions.statuses.reduce(
        (acc, el) => {
          if (
            !memoOrders.filteOptions.statuses ||
            memoOrders.filteOptions.statuses.length === 0
          )
            return false;

          return { status: [...acc.status, el] };
        },
        { status: [] }
      );

      const { customers } = memoOrders.filteOptions.customers.reduce(
        (acc, el) => {
          if (
            !memoOrders.filteOptions.customers ||
            memoOrders.filteOptions.customers.length === 0
          )
            return false;

          return {
            customers: [...acc.customers, { ...el, name: el.search_name }],
          };
        },
        { customers: [] }
      );

      const { bereitstellung } = memoOrders.filteOptions.bereitstellungs.reduce(
        (acc, el) => {
          if (
            !memoOrders.filteOptions.bereitstellungs ||
            memoOrders.filteOptions.bereitstellungs.length === 0
          )
            return false;

          return {
            bereitstellung: [
              ...acc.bereitstellung,
              { id: el === "Kronenholz im Bestand" ? 1 : 2, name: el },
            ],
          };
        },
        { bereitstellung: [] }
      );

      const { holzart } = memoOrders.filteOptions.wood_types.reduce(
        (acc, el) => {
          if (
            !memoOrders.filteOptions.wood_types ||
            memoOrders.filteOptions.wood_types.length === 0
          )
            return false;

          return { holzart: [...acc.holzart, { id: el, name: el }] };
        },
        { holzart: [] }
      );

      const { newOrders } = ordersArr.reduce(
        (acc, el) => {
          if (!ordersArr || ordersArr.length === 0) return false;

          return {
            newOrders: [
              ...acc.newOrders,
              {
                ...el,
                customer: `${el.customer.name} ${el.customer.surname}`,
                customer_id: el.customer.id,
                revier: el.revier.name,
                amount: `${el.amount}Fm`,
                nur_abtransport: el["nur_abtransport"] === 1 ? "Ja" : "Nein",
                price_per_fm: `${el["price_per_fm"]} €/Fm`,
                total_price: `€ ${el["total_price"]}`,
              },
            ],
          };
        },
        { newOrders: [] }
      );

      let uniqRevier = revier.reduce(
        (acc, cur) => [...acc.filter((obj) => obj.id !== cur.id), cur],
        []
      );
      let uniqStatus = status.reduce(
        (acc, cur) => [...acc.filter((obj) => obj.name !== cur.name), cur],
        []
      );
      let uniqCustomers = customers.reduce(
        (acc, cur) => [...acc.filter((obj) => obj.id !== cur.id), cur],
        []
      );
      let uniqBereitstellung = bereitstellung.reduce(
        (acc, cur) => [...acc.filter((obj) => obj.id !== cur.id), cur],
        []
      );
      let uniqHolzart = holzart.reduce(
        (acc, cur) => [...acc.filter((obj) => obj.name !== cur.name), cur],
        []
      );

      setFormattedOrders(newOrders);
      setFormattedRevier(uniqRevier);
      setFormattedStatus(uniqStatus);
      setFormattedCustomers(uniqCustomers);
      setFormattedBereitstellung(uniqBereitstellung);
      setFormattedHolzart(uniqHolzart);
    }
  }, [ordersArr, memoOrders]);

  const handleSearch = (e) => {
    e.preventDefault();
    ordersSearch(searchQuery, setOrdersArr, setPaginationInfo);
    setSearchQuery("");
  };
  const handleTrash = (e) => {
    setCurrentOrder(e.id);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const deleteOrder = () => {
    orderDelete(currentOrder, setCurrentOrder, dispatch);
    setOpenModal(false);
  };
  const handleFilter = () => {
    orderFilter(
      selectedStatus,
      selectedRevier,
      selectedCustomer,
      selectedHolzart,
      selectedBereitstellung,
      setOrdersArr,
      setOrdersData,
      setLoading,
      1,
      paginationInfo.per_page
    );
  };
  const handleClearFilter = () => {
    dispatch(getOrders());
    dispatch(setSelectedRevier([]));
    dispatch(setSelectedStatus([]));
    dispatch(setSelectedCustomer([]));
  };

  let exportRevier =
    selectedRevier.length > 0
      ? `&revier_id=${selectedRevier.map((el) => el.id).toString()}`
      : "";
  let exportStatus =
    selectedStatus.length > 0
      ? `&status_id=${selectedStatus.map((el) => el.id).toString()}`
      : "";
  let exportCustomer =
    selectedCustomer.length > 0
      ? `&customer_id=${selectedCustomer.map((el) => el.id).toString()}`
      : "";
  let exportBereintstellung =
    selectedBereitstellung.length > 0
      ? `&bereitstellung=${selectedBereitstellung
          .map((el) => el.id)
          .toString()}`
      : "";
  let exportWoodType =
    selectedHolzart.length > 0
      ? `&wood_type=${selectedHolzart.map((el) => el.name).toString()}`
      : "";

  return (
    <>
      {loading ? (
        <CircularProgress size={68} className={classes.fabProgress} />
      ) : (
        <>
          <div className={classes.titleWrapper}>
            <Title myClasses={classes.title}>
              Bestellungen
              <Button
                className={classes.link}
                variant="outlined"
                color="primary"
                href={`${BASE_URL}/api/orders/export?token=${checkTokenExpiration()}${exportRevier}${exportStatus}${exportCustomer}${exportBereintstellung}${exportWoodType}`}
              >
                Exportieren
              </Button>
            </Title>
            <form onSubmit={handleSearch}>
              <TextField
                label="Kunden Vor-oder Nachname oder Beides..."
                className={classes.textField}
                type="search"
                value={searchQuery}
                variant="outlined"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button type="submit" variant="outlined" color="primary">
                Bestellung suchen
              </Button>
            </form>
          </div>

          <Grid
            container
            spacing={3}
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.side}
          >
            <FilterSelect
              xs={12}
              md={6}
              lg={4}
              label="Revier suchen..."
              options={formattedRevier}
              value={selectedRevier}
              setter={(e) => dispatch(setSelectedRevier(e))}
            />
            <FilterSelect
              xs={12}
              md={6}
              lg={4}
              label="Bestellstatus suchen..."
              options={formattedStatus}
              value={selectedStatus}
              setter={(e) => dispatch(setSelectedStatus(e))}
            />
            <FilterSelect
              xs={12}
              md={6}
              lg={4}
              label="Kunden suchen..."
              options={formattedCustomers}
              value={selectedCustomer}
              setter={(e) => dispatch(setSelectedCustomer(e))}
            />
            <FilterSelect
              xs={12}
              md={6}
              lg={4}
              label="Bereitstellungsart suchen..."
              options={formattedBereitstellung}
              value={selectedBereitstellung}
              setter={(e) => dispatch(setSelectedBereitstellung(e))}
            />
            <FilterSelect
              xs={12}
              md={6}
              lg={4}
              label="Holzart suchen..."
              options={formattedHolzart}
              value={selectedHolzart}
              setter={(e) => dispatch(setSelectedHolzart(e))}
            />
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={4}
              direction="row"
              wrap="nowrap"
            >
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleFilter}
              >
                Filter
              </Button>
              <Button
                style={{ marginLeft: 15 }}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClearFilter}
              >
                Löschen
              </Button>
            </Grid>
          </Grid>

          <Table
            headCells={
              userLogIn.role === "admin" ? headCellsForAdmin : headCells
            }
            tableElements={formattedOrders}
            handleTrash={handleTrash}
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
            // setPaginationInfo={(info) => setPaginationInfo(revInfo => ({ ...revInfo, ...info }))}
          />

          <Dialog
            fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText variant="h4">
                Möchten Sie das Produkt wirklich löschen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={deleteOrder}
                variant="contained"
                size="large"
                color="secondary"
              >
                Ja
              </Button>
              <Button
                autoFocus
                onClick={handleClose}
                variant="contained"
                size="large"
                color="primary"
              >
                Nein
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
