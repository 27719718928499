import React, {useEffect, useState} from "react";
import moment from "moment";

import {Button, Grid, IconButton, Paper, SvgIcon, TextField, Typography, Link} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

import Title from "../../components/Title";
import Table from "../../components/Table/Table";
import GridElement from "../../components/GridElement";

import {useDispatch, useSelector} from "react-redux";
import {getComments, getCurrentCustomer, customerUpdate, commentDelete, addNewComment} from "../../helpers/api";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
  },
  input: {
    opacity: 1,
    color: "rgba(0, 0, 0, 1)",
  },
  mBottom: {
    marginBottom: theme.spacing(4),
  },
  heading: {
    margin: theme.spacing(3, 0)
  },
  card: {
    width: "95%",
    marginBottom: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  date: {
    padding: theme.spacing(0, 2),
  },
  editLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
}));

const headCells = [
  { id: 'article', label: 'Artikel', page: 'bestellungen' },
  { id: 'date', label: 'Datum' },
  { id: 'status', label: 'Status' },
  { id: 'total_price', label: 'Gesamtpreis' },
];

export default function Customer({history, match: {params}}) {
  const { userLogIn } = useSelector(state => state.login);
  const classes = useStyles();

  const dispatch = useDispatch();

  const [paginationInfo, setPaginationInfo] = useState({current_page: 1, per_page: 10, total: 0});
  const [customer, setCustomer] = useState({});
  const [orderHistory, setOrderHistory] = useState({});
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [newCommentText, setNewCommentText] = useState("");
  const [isEditeble, setIsEditeble] = useState(false);

  useEffect(() => {
    if (userLogIn.id !== undefined) getCurrentCustomer( params.id, setCustomer, setOrderHistory, paginationInfo )
  },[userLogIn, paginationInfo, dispatch]);

  console.log(paginationInfo);

  useEffect(() => {
    if (userLogIn.id) {
      if (orderHistory.current_page !== undefined && orderHistory.per_page !== undefined && orderHistory.total !== undefined) {
        if (orderHistory.current_page !== paginationInfo.current_page
          || orderHistory.per_page !== paginationInfo.per_page
          || orderHistory.total !== paginationInfo.total) {
          setPaginationInfo({current_page: orderHistory.current_page, per_page: orderHistory.per_page, total: orderHistory.total});
        }
      }
    }
  }, [userLogIn.id, orderHistory]);


  useEffect(() => {
    if (userLogIn.id !== undefined) {
      getCurrentCustomer(params.id, setCustomer, setOrderHistory);
      getComments(params.id, setComments, setIsFetching);
    }
  }, [params.id, userLogIn.id]);

  const handleChangeCustomer = (e) => {
    e.preventDefault();
    customerUpdate(params.id, customer, dispatch, history);
  };
  const handleDeleteComment = id => {
    commentDelete(id, params, setComments, setIsFetching, dispatch);
  };
  const handleAddNewComment = () => {
    addNewComment(params, newCommentText, setComments, setIsFetching, setNewCommentText, dispatch);
  };
  const handleInputChange = (e) => {
    const {name, value} = e.target;
    let updatedCustomer = {...customer};
    updatedCustomer[name] = value;
    setCustomer(updatedCustomer);
  };

  const profileFields = [
    { subtitle: "Titel", type: "text", name: "addressing", value: customer.addressing },
    { subtitle: "Name", type: "text", name: "name", value: customer.name },
    { subtitle: "Nachname", type: "text", name: "surname", value: customer.surname },
    { subtitle: "Straße/Hausnummer", type: "text", name: "street_and_house", value: customer.street_and_house },
    { subtitle: "Postleitzahl", type: "text", name: "post_code", value: customer.post_code },
    { subtitle: "Stadt", type: "text", name: "city", value: customer.city },
    { subtitle: "E-mail", type: "email", name: "email", value: customer.email },
    { subtitle: "Telefonnummer", type: "tel", name: "telephone", value: customer.telephone },
  ];

  return (
    <form onSubmit={handleChangeCustomer} >
      <Title>Kundenprofil</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.root}>
            <Typography variant="inherit" component="h2" className={classes.heading}>Kundendaten
              <IconButton style={{marginLeft: 20}} onClick={() => setIsEditeble(!isEditeble)}>
                <SvgIcon color="action">
                  <path
                    d="M16.84,2.73C16.45,2.73 16.07,2.88 15.77,3.17L13.65,5.29L18.95,10.6L21.07,8.5C21.67,7.89 21.67,6.94 21.07,6.36L17.9,3.17C17.6,2.88 17.22,2.73 16.84,2.73M12.94,6L4.84,14.11L7.4,14.39L7.58,16.68L9.86,16.85L10.15,19.41L18.25,11.3M4.25,15.04L2.5,21.73L9.2,19.94L8.96,17.78L6.65,17.61L6.47,15.29"/>
                </SvgIcon>
              </IconButton>
            </Typography>
            <Grid container spacing={3}>
              <GridElement sub={"Kundennummer:"} text={customer.customer_number}/>

              <Grid item container xs={12} alignItems="center">
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" component="p">Motorsägenschein:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  { customer?.licenses?.length > 0 && customer.licenses.map((item, idx) => <Typography key={idx} style={{marginBottom: 10}} variant="body2"><Link key={item.id} target="_blank" href={`${item.url}`}>{item.name || `Motorsägenschein #${idx + 1}`}</Link></Typography> ) }
                </Grid>
              </Grid>

              {Object.keys(customer).length > 0 && profileFields.map((el, idx)=>{
                return (
                  <Grid item container xs={12} alignItems="center" key={idx}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" component="p">{el.subtitle}:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        InputProps={{classes: {disabled: classes.input}}}
                        disabled={!isEditeble}
                        value={el.value}
                        fullWidth
                        variant="outlined"
                        label={el.subtitle}
                        name={el.name}
                        type={el.type}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                )
              })}

            </Grid>
          </Paper>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!isEditeble}
          >
            Speichern
          </Button>

          <Typography variant="inherit" component="h2" className={classes.heading}>Bestellhistorie</Typography>
          {orderHistory?.data?.length > 0 &&
            <Table
              className={classes.mBottom}
              smallTable headCells={headCells}
              tableElements={orderHistory.data}
              paginationInfo={paginationInfo}
              setPaginationInfo={setPaginationInfo}
            />
          }

        </Grid>

        <Grid item container xs={12} lg={4} alignItems="stretch">
          <Paper className={classes.root} style={{width: "100%"}}>
            <Typography variant="inherit" component="h2" className={classes.heading}>Kommentare</Typography>
            <Grid container wrap="wrap" style={{position: "relative", minHeight: 500, height: "100%"}} alignItems="flex-end">
              <Grid item xs={12} style={{
                position: "absolute",
                top: 0,
                bottom: 255,
                overflowY: "scroll",
                marginBottom: 20,
                width: "100%"
              }}>
                {comments.map(el => {
                  return (
                    <div key={el.id} className={classes.card}>
                      <div className={classes.text}>
                        <Typography style={{wordWrap: "break-word"}} variant="body2" component="p" gutterBottom>
                          {el.content}
                        </Typography>
                      </div>
                      <div className={classes.date}>
                        <Typography variant="body2" color="textSecondary" component="p">hinzugefügt am {moment(el.created_at).format("MMMM DD, YYYY")}</Typography>
                        <Button color="secondary" size="small" onClick={() => handleDeleteComment(el.id)}>Kommentar löschen</Button>
                      </div>
                    </div>
                  )
                })}
              </Grid>

              <Grid item xs={12} style={{marginBottom: 65}}>
                <TextField
                  fullWidth
                  label="Kommentare"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={newCommentText}
                  onChange={(e) => setNewCommentText(e.target.value.slice(0, 500))}
                />
                <Button
                  disabled={isFetching}
                  style={{marginTop: 10}}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewComment}
                >
                  Kommentar hinzufügen
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}