import { ActionType } from "../actionTypes";

export function setSelectedRevier(selectedRevier) {
  return {
    type: ActionType.SET_ORDERS_FILTERS_SELECTED_REVIER,
    payload: { selectedRevier },
  };
}

export function setSelectedStatus(selectedStatus) {
  return {
    type: ActionType.SET_ORDERS_FILTERS_SELECTED_STATUS,
    payload: { selectedStatus },
  };
}

export function setSelectedBereitstellung(selectedBereitstellung) {
  return {
    type: ActionType.SET_ORDERS_FILTERS_SELECTED_BEREITSTELLUNG,
    payload: { selectedBereitstellung },
  };
}

export function setSelectedHolzart(selectedHolzart) {
  return {
    type: ActionType.SET_ORDERS_FILTERS_SELECTED_HOLZART,
    payload: { selectedHolzart },
  };
}

export function setSelectedCustomer(selectedCustomer) {
  return {
    type: ActionType.SET_ORDERS_FILTERS_SELECTED_CUSTOMER,
    payload: { selectedCustomer },
  };
}
