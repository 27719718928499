import { ActionType } from '../actionTypes';
import axios from "axios";

export function getOrdersStart() {
  return {
    type: ActionType.GET_ORDERS_START,
  }
}
export function getOrdersSuccess(data) {
  return {
    type: ActionType.GET_ORDERS_SUCCESS,
    data
  }
}
export function getOrdersFailure(error, errorMessage) {
  return {
    type: ActionType.GET_ORDERS_FAILURE,
    error,
    errorMessage
  }
}

export const getOrders = (paginate = {}) => {
  const { current_page, per_page } = paginate;
  return dispatch => {
    dispatch(getOrdersStart());
    axios.get('/api/orders', {params: {page: current_page, per_page} })
      .then((res) => {
        dispatch(getOrdersSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getOrdersFailure(true, (error?.response?.data || error.message)));
      });
  }
};