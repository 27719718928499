import { ActionType } from '../actionTypes';
import axios from "axios";

export function getCustomersStart() {
  return {
    type: ActionType.GET_CUSTOMERS_START,
  }
}
export function getCustomersSuccess(data) {
  return {
    type: ActionType.GET_CUSTOMERS_SUCCESS,
    data
  }
}
export function getCustomersFailure(error, errorMessage) {
  return {
    type: ActionType.GET_CUSTOMERS_FAILURE,
    error,
    errorMessage
  }
}

export const getCustomers = (paginate= {}) => {
  const { current_page, per_page } = paginate;
  return dispatch => {
    dispatch(getCustomersStart());
    axios.get('/api/customers', {params: {page: current_page, per_page} })
      .then((res) => {
        dispatch(getCustomersSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getCustomersFailure(true, (error?.response?.data || error.message)));
      });
  }
};