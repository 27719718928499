import { ActionType } from '../actionTypes';
import axios from "axios";

export function getMailTemplatesStart() {
  return {
    type: ActionType.GET_MAILTEMPLATES_START,
  }
}
export function getMailTemplatesSuccess(data) {
  return {
    type: ActionType.GET_MAILTEMPLATES_SUCCESS,
    data
  }
}
export function getMailTemplatesFailure(error, errorMessage) {
  return {
    type: ActionType.GET_MAILTEMPLATES_FAILURE,
    error,
    errorMessage
  }
}

export const getMailTemplates = ( paginate={} ) => {
  const { current_page, per_page } = paginate;
  return dispatch => {
    dispatch(getMailTemplatesStart());
    axios.get('/api/mail-templates', {params: {page: current_page, per_page} })
      .then((res) => {
        dispatch(getMailTemplatesSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getMailTemplatesFailure(true, (error?.response?.data || error.message)));
      });
  }
};