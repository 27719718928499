import React from "react";
import {Route, Switch} from "react-router-dom";
import {Link} from "react-router-dom";

import Logo from "../../assets/img/hessenforst-logo.svg";

import {AppBar, CardMedia, Container, CssBaseline, Toolbar, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {blueGrey} from "@material-ui/core/colors";

import LogInForm from "./LogInForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: blueGrey["900"],
    paddingLeft: 20,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: blueGrey["50"],
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight + 8}px)`,
    overflow: 'auto',
  },
  logo: {
    width: 235,
  },
}));

export default function LogIn() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline/>

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link to="/" >
            <CardMedia component="img" src={Logo} />
          </Link>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Grid container className={classes.container} justify="center" alignItems="center" direction="column" >
            <CardMedia component="img" className={classes.logo} src={Logo} />

            <Switch>
              <Route exact path="/login" render={(props) => <LogInForm {...props} />}/>
              <Route exact path="/login/reset" render={(props) => <ForgotPasswordForm {...props} />}/>
            </Switch>

          </Grid>
        </Container>
      </main>
    </div>
  );
}