import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/actions/getProductsAction";
import { getProductsByRevier } from "../../redux/actions/getProductsByRevierAction";

import Title from "../../components/Title";
import Table from "../../components/Table/Table";
import FilterSelect from "../../components/FilterSelect";
import { productDelete } from "../../helpers/api";
import BASE_URL from "../../helpers/url";
import { checkTokenExpiration } from "../../helpers/token";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: blueGrey["50"],
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  breadcrumbs: {
    padding: theme.spacing(2, 4),
    backgroundColor: "#fff",
  },
  title: {
    display: "inline-flex",
  },
  link: {
    marginLeft: theme.spacing(4),
    textDecoration: "none",
  },
  fabProgress: {
    margin: "20% 50%",

  },
}));

const headCellsForAdmin = [
  { id: 'name', label: 'Artikel', page: 'alle-produkte' },
  { id: 'forstamt', label: 'Forstamt' },
  { id: 'revier', label: 'Revier' },
  { id: 'bereitstellung', label: 'Bereitstellung' },
  { id: 'wood_type', label: 'Holzart' },
  { id: 'amount', label: 'verfügbare Menge' },
  { id: 'price_per_fm', label: 'Preis/FM' },
  { id: 'stock', label: 'Bestand' },
];
const headCells = [
  { id: 'name', label: 'Artikel', page: 'alle-produkte' },
  { id: 'revier', label: 'Revier' },
  { id: 'bereitstellung', label: 'Bereitstellung' },
  { id: 'wood_type', label: 'Holzart' },
  { id: 'amount', label: 'verfügbare Menge' },
  { id: 'price_per_fm', label: 'Preis/FM' },
  { id: 'stock', label: 'Bestand' },
];

export default function AllProducts() {
  const classes = useStyles();

  const { userLogIn } = useSelector(state => state.login);
  const { products, isFetchingProducts } = useSelector(state => state.products);
  const { productsByRevier } = useSelector(state => state.productsByRevier);
  const { reviers } = useSelector(state => state.reviers);

  const dispatch = useDispatch();

  const [paginationInfo, setPaginationInfo] = useState({ current_page: 1, per_page: 10, total: 0 });
  const [productsArr, setProductsArr] = useState([]);
  const [formattedProducts, setFormattedProducts] = useState([]);
  const [formattedRevier, setFormattedRevier] = useState([]);
  const [selectedRevier, setSelectedRevier] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (userLogIn.id !== undefined && !selectedRevier.length) dispatch(getProducts(paginationInfo))
  }, [userLogIn.id, paginationInfo, selectedRevier, dispatch]);

  useEffect(() => {
    if (userLogIn.id !== undefined && selectedRevier.length > 0) {
      if (productsByRevier.current_page !== paginationInfo.current_page
        || productsByRevier.per_page !== paginationInfo.per_page
        || productsByRevier.total !== paginationInfo.total) {
        dispatch(getProductsByRevier(selectedRevier.map(el => el.id), paginationInfo));
      }
    }
  }, [userLogIn.id, paginationInfo, dispatch]);

  useEffect(() => {
    axios.get(`/api/reviers?onlyWithProducts`)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setFormattedRevier(
            res.data.reduce((unique, item) => (unique.map(el => el.name).includes(item.name) ? unique : [...unique, item]), [])
          );
        }
      })
  }, [reviers]);
  useEffect(() => {
    if (products && Object.keys(products).length > 0) {
      setProductsArr(products.data);
      if (products.current_page !== paginationInfo.current_page
        || products.per_page !== paginationInfo.per_page
        || products.total !== paginationInfo.total) {
        setPaginationInfo({ current_page: products.current_page, per_page: products.per_page, total: products.total });
      }
    }
  }, [products]);

  useEffect(() => {
    if (Object.keys(productsByRevier).length > 0) {
      setProductsArr(productsByRevier.data);
      if (productsByRevier.current_page !== paginationInfo.current_page
        || productsByRevier.per_page !== paginationInfo.per_page
        || productsByRevier.total !== paginationInfo.total) {
        setPaginationInfo({ current_page: productsByRevier.current_page, per_page: productsByRevier.per_page, total: productsByRevier.total });
      }
    }
  }, [productsByRevier]);
  useEffect(() => {
    const { newProducts } = productsArr.reduce((acc, el) => {
      if (!productsArr || productsArr.length === 0) return {};

      return {
        newProducts: [...acc.newProducts, {
          ...el,
          revier: el.revier.name,
          bereitstellung: el.bereitstellung.name,
          wood_type: el["wood_type"].name,
          price_per_fm: `${el["price_per_fm"]} €/Fm`,
          amount: `${el.amount}Fm`
        }]
      }
    }, { newProducts: [] });
    setFormattedProducts(newProducts);
  }, [productsArr]);

  const handleTrash = (e) => {
    setCurrentProduct(e.id);
    setOpenModal(true)
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const deleteProduct = () => {
    productDelete(currentProduct, setCurrentProduct, dispatch);
    setOpenModal(false);
  };
  const handleFilter = () => {
    dispatch(getProductsByRevier(selectedRevier.map(el => el.id), { ...paginationInfo, current_page: 1 }));
  };
  const handleClearFilter = () => {
    dispatch(getProducts());
    setSelectedRevier([]);
  };

  let exportRevier = selectedRevier.length > 0 ? `&revier_id=${selectedRevier.map(el => el.id).toString()}` : "";

  return (
    <>
      {isFetchingProducts
        ? (<CircularProgress size={68} className={classes.fabProgress} />)
        : (<>
          <Title myClasses={classes.title}>
            Alle Produkte
            {userLogIn.role !== "revier"
              && <Button className={classes.link} to={"/neuen-artikel-hinzufügen"} component={Link} variant="outlined" color="primary">Neues hinzufügen</Button>
            }

            <Button
              className={classes.link}
              variant="outlined"
              color="primary"
              href={`${BASE_URL}/api/products/export?token=${checkTokenExpiration()}${exportRevier}`}
            >Exportieren</Button>
          </Title>

          <Grid container style={{ marginTop: 50 }} >
            <FilterSelect xs={12} md={6} lg={3} label="Revier suchen..." options={formattedRevier} value={selectedRevier} setter={setSelectedRevier} />
            <Button variant="outlined" color="primary" onClick={handleFilter} >
              Filter
            </Button>
            <Button style={{ marginLeft: 15 }} variant="outlined" color="secondary" onClick={handleClearFilter} >
              Löschen
            </Button>
          </Grid>

          <Table
            headCells={userLogIn.role === "admin" ? headCellsForAdmin : headCells}
            tableElements={formattedProducts}
            handleTrash={handleTrash}
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
          />

          <Dialog
            fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText variant="h4">
                Möchten Sie das Produkt wirklich löschen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={deleteProduct} variant="contained" size="large" color="secondary">
                Ja
              </Button>
              <Button autoFocus onClick={handleClose} variant="contained" size="large" color="primary">
                Nein
              </Button>
            </DialogActions>
          </Dialog>
        </>)
      }
    </>
  );
}
