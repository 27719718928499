import React, {useEffect, useState} from 'react';
import {Link as RouterLink, Redirect, Route, Switch} from "react-router-dom";

import {Breadcrumbs, Container, CssBaseline, Link} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {blueGrey} from "@material-ui/core/colors";

import Header from "./Header";
import SideBar from "./SideBar";
import AllProducts from "../pages/AllProducts/AllProducts";
import Product from "../pages/Product/Product";
import Orders from "../pages/Orders/Orders";
import Order from "../pages/Order/Order";
import Customers from "../pages/Customers/Customers";
import Customer from "../pages/Customer/Customer";
import Reports from "../pages/Reports/Reports";
import Emails from "../pages/Emails/Emails";
import Settings from "../pages/Settings/Settings";
import AddNew from "../pages/AddNew/AddNew";
import EmailsTemplate from "../pages/EmailsTemplate/EmailsTemplate";

import {getStatuses} from "../redux/actions/getStatusesAction";
import {getWoodTypes} from "../redux/actions/getWoodTypesAction";
import {getReviers} from "../redux/actions/getReviersAction";
import {getMailTemplates} from "../redux/actions/getTemplatesAction";
import {getForstamts} from "../redux/actions/getForstamtsAction";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: blueGrey["50"],
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  breadcrumbs: {
    padding: theme.spacing(2, 4),
    backgroundColor: "#fff",
    textTransform: "capitalize"
  },
}));

export default function Authenticated() {
  const { userLogIn } = useSelector(state => state.login);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (userLogIn.id !== undefined) {
      dispatch(getStatuses());
      dispatch(getWoodTypes());
      dispatch(getMailTemplates());
      dispatch(getReviers());
      dispatch(getForstamts());
    }
  }, [userLogIn.id, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <Header mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <SideBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Route>
          {({location}) => {
            const pathnames = location.pathname.split('/').filter(x => x);

            return (
              <Breadcrumbs className={classes.breadcrumbs} maxItems={5} separator={<NavigateNextIcon fontSize="small"/>}>
                <Link color="inherit" to="/" component={RouterLink} >Startseite</Link>
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  const formattedValue = value.split("-").join(' ');

                  return last ? (
                    <Typography color="textPrimary" key={to}>{formattedValue}</Typography>
                  ) : (
                    <Link color="inherit" to={to} key={to} component={RouterLink} >{formattedValue}</Link>
                  );
                })}
              </Breadcrumbs>
            );
          }}
        </Route>
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/bestellungen" />}/>
            <Route exact path="/bestellungen" render={(props) => <Orders {...props} />}/>
            <Route path="/bestellungen/:id" render={(props) => <Order {...props} />}/>
            <Route exact path="/kunden" render={(props) => <Customers {...props} />}/>
            <Route path="/kunden/:id" render={(props) => <Customer {...props} />}/>
            <Route path="/berichte" render={(props) => userLogIn.role !== "revier" && <Reports {...props} />}/>
            <Route exact path="/emails" render={(props) => userLogIn.role === "admin" && <Emails {...props} />}/>
            <Route path="/emails/:templateId" render={(props) => userLogIn.role === "admin" && <EmailsTemplate {...props} />}/>
            <Route path="/einstellungen" render={(props) => userLogIn.role !== "revier" && <Settings {...props} />}/>
            <Route exact path="/alle-produkte" render={(props) => <AllProducts {...props} />}/>
            <Route path="/alle-produkte/:id" render={(props) => <Product {...props} />}/>
            <Route path="/neuen-artikel-hinzufügen" render={(props) => userLogIn.role !== "revier" && <AddNew {...props} />}/>
          </Switch>
        </Container>
      </main>
    </div>
  );
}