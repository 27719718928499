
const selectBereitstellung = [
  { "value": "Kronenholz-im-Bestand", "name": "Kronenholz im Bestand", "selectName": "bereitstellung"},
  { "value": "gerückt-am-Weg", "name": "gerückt am Weg", "selectName": "bereitstellung" },
];

const chartMockData = [
  {date: 'Jan', sales: 0},
  {date: 'Feb', sales: 0},
  {date: 'Mar', sales: 0},
  {date: 'Apr', sales: 0},
  {date: 'May', sales: 0},
  {date: 'Jun', sales: 0},
  {date: 'July', sales: 0},
  {date: 'Aug', sales: 0},
  {date: 'Sep', sales: 0},
  {date: 'Oct', sales: 0},
  {date: 'Nov', sales: 0},
  {date: 'Dec', sales: 0},
];

export {selectBereitstellung, chartMockData};
