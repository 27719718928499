import { ActionType } from "../actionTypes";

const initialState = {
  selectedRevier: [],
  selectedStatus: [],
  selectedBereitstellung: [],
  selectedHolzart: [],
  selectedCustomer: [],
};

export default function setOrdersFiltersReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ActionType.SET_ORDERS_FILTERS_SELECTED_REVIER:
      return { ...state, selectedRevier: payload.selectedRevier };
    case ActionType.SET_ORDERS_FILTERS_SELECTED_STATUS:
      return { ...state, selectedStatus: payload.selectedStatus };
    case ActionType.SET_ORDERS_FILTERS_SELECTED_BEREITSTELLUNG:
      return {
        ...state,
        selectedBereitstellung: payload.selectedBereitstellung,
      };
    case ActionType.SET_ORDERS_FILTERS_SELECTED_HOLZART:
      return { ...state, selectedHolzart: payload.selectedHolzart };
    case ActionType.SET_ORDERS_FILTERS_SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: payload.selectedCustomer };

    default:
      return state;
  }
}
