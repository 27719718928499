import { combineReducers } from "redux";
import { reducers } from "./reducers/reducers";

// Combine Reducers
const rootReducer = combineReducers({
  login: reducers.logInReducer,
  products: reducers.getProductsReducer,
  customers: reducers.getCustomersReducer,
  orders: reducers.getOrdersReducer,
  ordersFilters: reducers.setOrdersFiltersReducer,
  reviers: reducers.getReviersReducer,
  woodTypes: reducers.getWoodTypesReducer,
  statuses: reducers.getStatusesReducer,
  templates: reducers.getTemplatesReducer,
  forstamts: reducers.getForstamtsReducer,
  reviersByForstamt: reducers.getReviersByForstamtReducer,
  productsByRevier: reducers.getProductsByRevierReducer,
});

export default rootReducer;
