import getProductsReducer from "./getProductsReducer";
import getCustomersReducer from "./getCustomersReducer";
import getOrdersReducer from "./getOrdersReducer";
import setOrdersFiltersReducer from "./setOrdersFiltersReducer";
import getReviersReducer from "./getReviersReducer";
import getWoodTypesReducer from "./getWoodTypesReducer";
import getStatusesReducer from "./getStatusesReducer";
import getTemplatesReducer from "./getTemplatesReducer";
import getForstamtsReducer from "./getForstamtsReducer";
import getReviersByForstamtReducer from "./getReviersByForstamtReducer";
import getProductsByRevierReducer from "./getProductsByRevierReducer";
import logInReducer from "./logInReducer";

export const reducers = {
  getProductsReducer,
  getCustomersReducer,
  getOrdersReducer,
  setOrdersFiltersReducer,
  getReviersReducer,
  getWoodTypesReducer,
  getStatusesReducer,
  getTemplatesReducer,
  getForstamtsReducer,
  getReviersByForstamtReducer,
  getProductsByRevierReducer,
  logInReducer,
};
