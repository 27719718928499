import { ActionType } from '../actionTypes';
import {getHelper} from "./getHelper";

export function getStatusesStart() {
  return {
    type: ActionType.GET_STATUSES_START,
  }
}
export function getStatusesSuccess(data) {
  return {
    type: ActionType.GET_STATUSES_SUCCESS,
    data
  }
}
export function getStatusesFailure(error, errorMessage) {
  return {
    type: ActionType.GET_STATUSES_FAILURE,
    error,
    errorMessage
  }
}

export const getStatuses = () => {
  return dispatch => {
    getHelper('/api/statuses', getStatusesStart, getStatusesSuccess, getStatusesFailure, dispatch);
  }
};