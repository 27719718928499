import { ActionType } from '../actionTypes';
import axios from "axios";

export function getProductsByRevierStart() {
  return {
    type: ActionType.GET_PRODUCTS_BY_REVIER_START,
  }
}
export function getProductsByRevierSuccess(data) {
  return {
    type: ActionType.GET_PRODUCTS_BY_REVIER_SUCCESS,
    data
  }
}
export function getProductsByRevierFailure(error, errorMessage) {
  return {
    type: ActionType.GET_PRODUCTS_BY_REVIER_FAILURE,
    error,
    errorMessage
  }
}

export const getProductsByRevier = (ids, paginationInfo = {}) => {
  const { current_page, per_page } = paginationInfo;
  
  return dispatch => {
    dispatch(getProductsByRevierStart());
    axios.get(`/api/products/filter`, {params: {revier: ids, page: current_page, per_page} } )
      .then((res) => {
        dispatch(getProductsByRevierSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getProductsByRevierFailure(true, (error?.response?.data || error.message)));
      });
  }
};