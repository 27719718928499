import React, {useEffect} from "react";
import axios from "axios";

import {Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {makeStyles} from "@material-ui/core/styles";

import {useDispatch, useSelector} from "react-redux";
import {getReviers} from "../redux/actions/getReviersAction";
import {getWoodTypes} from "../redux/actions/getWoodTypesAction";
import {getReviersByForstamt} from "../redux/actions/getReviersByForstamtAction";

import {selectBereitstellung} from "../helpers/data";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
  },
  border: { borderRadius:4, border: "1px solid #c4c4c4" },
}));

export default function ProductLayout(props) {
  const classes = useStyles();
  
  const { userLogIn } = useSelector(state => state.login);
  const { forstamts } = useSelector(state => state.forstamts);
  const { reviersByForstamt } = useSelector(state => state.reviersByForstamt);
  const { woodTypes } = useSelector(state => state.woodTypes);
  const dispatch = useDispatch();

  const {currentRadioForstamt, setCurrentRadioForstamt, currentRevier, setCurrentRevier, currentWoodType, bereitstellung, inputHolzlange, inputAmount, inputPreis,
    setInputHolzlange, setInputAmount, setInputPreis, setBereitstellung, setCurrentWoodType,
    handleSubmit, buttonText } = props;

  useEffect(() => {
    if (userLogIn.id !== undefined){
      dispatch(getReviers());
      dispatch(getWoodTypes());
    }
  },[dispatch, userLogIn]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (userLogIn.id !== undefined && currentRadioForstamt.id !== undefined){
      dispatch(getReviersByForstamt(currentRadioForstamt.id, { cancelToken: source.token }));
    }
    return () => {
      source.cancel();
    }
  }, [userLogIn, currentRadioForstamt, dispatch]);

  useEffect(() => {
    if (userLogIn.id !== undefined && reviersByForstamt.length !== 0){
      let selectedRevier = reviersByForstamt.find(x=>x.name === currentRevier.name);
      if(selectedRevier?.id !== undefined ){
        setCurrentRevier(selectedRevier);
      } else {
        setCurrentRevier(reviersByForstamt[0]);
        setCurrentWoodType({});
      }
    }
  },[userLogIn, reviersByForstamt, currentRevier]);

  const handleCheckboxRevier = (el) => {
    setCurrentRevier(el);
    setCurrentWoodType({});
  };
  const handleCheckboxForstamt = (el) => {
    setCurrentRadioForstamt(el);
  };

  const handleInput = (e) => {
    const {name, value} = e.target;
    if (name === "inputHolzlange") setInputHolzlange(value.replace(/[^\d-]/, "").slice(0, 5));
    if (name === "inputAmount") setInputAmount(value.replace(/[^0-9]/g, "").slice(0, 8));
    if (name === "inputPreis") setInputPreis(value.replace(/[^0-9.]/g, "").slice(0, 8));
  };

  const handleInputBlur = (e) => {
    const {name, value} = e.target;
    if (name === "inputHolzlange") {
      if ( value.match(/^[0-9]{1,2}[-]{1}[0-9]{1,2}/) === null ) {
        setInputHolzlange("");
      }
    }
    if (name === "inputAmount") {
      let newInputAmount = value - (value % 5);
      setInputAmount(newInputAmount.toString());
    }
  };

  const showWoodTipesbyReviersID = woodTypes.filter(el => el.revier_id === currentRevier.id);

  return (
    <form onSubmit={handleSubmit} >
      <Paper className={classes.root} >
        <Grid container >
          <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Forstamt</Typography>
          <Grid container item xs={12} md={9} alignItems="center" >
            <RadioGroup name="forstamt" style={{width: "100%"}}>
              <Grid container spacing={3} alignItems="center" className={classes.border}>
                {forstamts.length > 0 && forstamts.map((el) => {
                  return (
                    <Grid item key={el.id} xs={12} sm={6} lg={4} xl={3}>
                      <FormControlLabel
                        control={<Radio color="primary"/>}
                        label={el.name}
                        labelPlacement="end"
                        value={el.name}
                        checked={el.name === currentRadioForstamt.name}
                        onChange={() => handleCheckboxForstamt(el)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container style={{marginTop: 50}} >
          <Typography variant="inherit" component="h3" style={{width:150}} gutterBottom>Revier</Typography>
          <Grid container item xs={12} md={9} alignItems="center" >
            <RadioGroup name="revier" style={{width: "100%"}}>
              {reviersByForstamt.length > 0 &&
              <Grid container spacing={3} alignItems="center" className={classes.border}>
                {reviersByForstamt.map((el) => {
                  return (
                    <Grid item key={el.id} xs={12} md={6} lg={4} xl={3}>
                      <FormControlLabel
                        control={<Radio color="primary"/>}
                        label={el.name}
                        labelPlacement="end"
                        value={el.name}
                        checked={el.name === currentRevier.name}
                        forstamt_id={el.forstamt_id}
                        onChange={() => handleCheckboxRevier(el)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              }
            </RadioGroup>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.root}>
        <h2 className={classes.heading2}>Artikel</h2>
        <Grid spacing={3} container justify="space-between" alignItems="center">
          <Grid item xs={12} md={6} lg={6} xl={3}>
            Bereitstellung
            <Autocomplete
              disableClearable
              value={bereitstellung}
              onChange={(e,option) => setBereitstellung(option)}
              options={selectBereitstellung}
              getOptionLabel={option => option.name ? option.name : ""}
              getOptionSelected={(option, value) => option.name === value.name}
              renderInput={params => (
                <TextField {...params} label="Bereitstellung" variant="outlined" fullWidth margin="normal"/>
              )}
              renderOption={(option, {inputValue}) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={3}>
            Holzart
            <Autocomplete
              disabled={currentRevier.length===0}
              disableClearable
              value={currentWoodType}
              onChange={(e,option) => setCurrentWoodType(option)}
              options={showWoodTipesbyReviersID}
              getOptionLabel={option => option.name ? option.name : ""}
              getOptionSelected={(option, value) => option.name === value.name}
              renderInput={params => (
                <TextField {...params} label="Holzart" variant="outlined" fullWidth margin="normal"/>
              )}
              renderOption={(option, {inputValue}) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={2} >
            Holzlänge
            <TextField
              fullWidth
              variant="outlined"
              label="Holzlänge"
              type="text"
              name="inputHolzlange"
              value={inputHolzlange}
              inputProps={{ pattern: "^[0-9]{1,2}[-]{1}[0-9]{1,2}", title: "xx-xx" }}
              onChange={handleInput}
              onBlur={handleInputBlur}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={2} >
            Menge
            <TextField
              fullWidth
              variant="outlined"
              label="Menge"
              type="number"
              name={"inputAmount"}
              value={inputAmount}
              onChange={handleInput}
              onBlur={handleInputBlur}
              inputProps={{ min: "0", step: "5" }}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={2} >
            Preis/FM
            <TextField
              fullWidth
              variant="outlined"
              label="Preis/FM"
              type="number"
              name={"inputPreis"}
              value={inputPreis}
              onChange={handleInput}
              inputProps={{ min: "0", step: "0.01"}}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper spacing={3} className={classes.root}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={5} lg={4}>
            <h2 className={"text-nowrap"}>Artikel name</h2>
          </Grid>
          <Grid item md={7} lg={8}>
            <p className={`mb-0`}>
              {`${bereitstellung && bereitstellung.name !== undefined ? bereitstellung.name + `, ` : ""}
                ${currentWoodType && currentWoodType.name !== undefined ? currentWoodType.name + `, `: ""}
                ${inputHolzlange && inputHolzlange + `m, `}
                ${inputPreis && `zu ${inputPreis} €/Fm`}`}
            </p>
          </Grid>
        </Grid>
      </Paper>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!bereitstellung || !currentWoodType || !inputAmount || !inputPreis}
      >
        {buttonText}
      </Button>
    </form>
  );
}