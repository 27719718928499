import axios from "axios";

export const getHelper = (string, start, success, failure, dispatch) => {
  dispatch(start());
  axios.get(string)
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((error) => {
      dispatch(failure(true, (error?.response?.data || error.message)));
    });
};