import React from 'react';
import {Link, NavLink} from "react-router-dom";
import clsx from 'clsx';

import {Drawer, List, ListItem, Typography, Collapse, Hidden, CardMedia} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {blueGrey, blue} from "@material-ui/core/colors";
import {ExpandLess, ExpandMore} from '@material-ui/icons';

import Logo from "../assets/img/HessenForst.jpg";
import {useSelector} from "react-redux";
const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 240,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbarColor: {
    backgroundColor: theme.palette.common.white,
  },
  drawerPaper: {
    width: 240,
    borderRight: "none",
    backgroundColor: blueGrey["900"],
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  summary: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    backgroundColor: blue["700"],
    justifyContent: "space-between",
    color: "#fff",
    "&:hover": {
      backgroundColor: blue["700"],
    }
  },
  menuText: {
    paddingLeft: theme.spacing(5),
    color: "#fff",
    transition: ".3s ease-in-out",
  },
  active: {
    fontWeight: "bold",
    paddingLeft: theme.spacing(7),
  },
  media: {
    width: 150,
    marginLeft: 35,
    //marginTop: 12,
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
}));

export default function SideBar({mobileOpen, handleDrawerToggle}) {
  const { userLogIn } = useSelector(state => state.login);
  const classes = useStyles();

  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);

  const handleClick = (list) => {
    if(list==="list1") setOpen1(!open1);
    if(list==="list2") setOpen2(!open2);
  };

  const list = (
    <div>
      <div className={clsx(classes.toolbar, classes.toolbarColor)}>
        <Link to="/" >
          <CardMedia className={classes.media} component="img" src={Logo} />
        </Link>
      </div>
      <List component="nav" >
        {userLogIn.role !== "revier" &&
        <ListItem button className={classes.summary} >
          {/*<ListItemIcon>*/}
          {/*  <SendIcon />*/}
          {/*</ListItemIcon>*/}
          <Typography variant="h6" component="p" >Menü</Typography>
          {open1 ? <ExpandLess onClick={()=>handleClick("list1")} /> : <ExpandMore onClick={()=>handleClick("list1")} />}
        </ListItem >}
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" >
            {userLogIn.role !== "revier"
              && <ListItem button className={classes.menuText} activeClassName={classes.active} component={NavLink} to="/berichte" >
                  {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
                  Berichte
                </ListItem>}
            {userLogIn.role === "admin"
              && <ListItem button className={classes.menuText} activeClassName={classes.active} component={NavLink} to="/emails" >
                  {/*<ListItemIcon><MailIcon /></ListItemIcon>*/}
                  E-Mails
                </ListItem>}
            {userLogIn.role !== "revier"
            && <ListItem button className={classes.menuText} activeClassName={classes.active} component={NavLink} to="/einstellungen" >
                  {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
                  Einstellungen
               </ListItem>
            }
          </List>
        </Collapse>

        <ListItem button className={classes.summary} component={NavLink} to="/bestellungen" >
          {/*<ListItemIcon>*/}
          {/*  <InboxIcon />*/}
          {/*</ListItemIcon>*/}
          <Typography variant="h6" component="p" >Bestellungen</Typography>
        </ListItem>
        <ListItem button className={classes.summary} component={NavLink} to="/kunden" >
          {/*<ListItemIcon>*/}
          {/*  <InboxIcon />*/}
          {/*</ListItemIcon>*/}
          <Typography variant="h6" component="p" >Kunden</Typography>
        </ListItem>

        <ListItem button className={classes.summary} component={NavLink} to="/alle-produkte" >
          {/*<ListItemIcon>*/}
          {/*  <InboxIcon />*/}
          {/*</ListItemIcon>*/}
          <Typography variant="h6" component="p" >Produkte</Typography>
          {open2 ? <ExpandLess onClick={()=>handleClick("list2")} /> : <ExpandMore onClick={()=>handleClick("list2")} />}
        </ListItem>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" >
            <ListItem button className={classes.menuText} activeClassName={classes.active} component={NavLink} to="/alle-produkte" >
              {/*<ListItemIcon><InboxIcon /></ListItemIcon>*/}
              Alle Produkte
            </ListItem>

            {userLogIn.role !== "revier"
            && <ListItem button className={classes.menuText} activeClassName={classes.active} component={NavLink} to="/neuen-artikel-hinzufügen" >
                {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
                Neues hinzufügen
              </ListItem>
            }
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{keepMounted: true}}// Better open performance on mobile.
        >
          {list}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {list}
        </Drawer>
      </Hidden>
    </nav>
  );
}