import { ActionType } from '../actionTypes';
import axios from "axios";

export function getProductsStart() {
  return {
    type: ActionType.GET_PRODUCTS_START,
  }
}
export function getProductsSuccess(data) {
  return {
    type: ActionType.GET_PRODUCTS_SUCCESS,
    data
  }
}
export function getProductsFailure(error, errorMessage) {
  return {
    type: ActionType.GET_PRODUCTS_FAILURE,
    error,
    errorMessage
  }
}

export const getProducts = (paginate = {}) => {
  const { current_page, per_page } = paginate;
  return dispatch => {
    dispatch(getProductsStart());
    axios.get('/api/products', {params: {page: current_page, per_page} })
      .then((res) => {
        dispatch(getProductsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getProductsFailure(true, (error?.response?.data || error.message)));
      });
  }
};