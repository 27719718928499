import React from "react";
import axios from "axios";
import moment from "moment";
import {getStatuses} from "../redux/actions/getStatusesAction";
import {getProducts} from "../redux/actions/getProductsAction";
import {getCustomers} from "../redux/actions/getCustomersAction";
import {getOrders} from "../redux/actions/getOrdersAction";
import {getReviersByForstamt} from "../redux/actions/getReviersByForstamtAction";
import {getWoodTypes} from "../redux/actions/getWoodTypesAction";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {logInFailure, logInStart, logInSuccess} from "../redux/actions/logInAction";


export const refreshToken = () => {
  return dispatch => {
    dispatch(logInStart());
    axios.get('/api/refresh')
      .then(res => {
        localStorage.setItem("token", res.data.jwt.access_token);
        dispatch(logInSuccess(res.data.user));
      })
      .catch(error => {
        dispatch(logInFailure(true, (error?.response?.data || error.message)));
      });
  }
};

export const resetPassword = (email, enqueueSnackbar, closeSnackbar, setIsFetching) => {
  setIsFetching(true);
  axios.post('/api/request-password-reset', { email: email })
    .then((res) => {
      enqueueSnackbar(res?.data || res.message || res, { variant: 'success', action: key => (
          <IconButton key="close" color="inherit" onClick={() => closeSnackbar(key)}><CloseIcon /> </IconButton>
        ) });
      setIsFetching(false);
    })
    .catch( err => {
    setIsFetching(false);
  })
};

export const confirmNewPassword = (pass, token, history, setIsFetching) => {
  setIsFetching(true);
  axios.post('/api/confirm-password-reset', {"new_password": pass, "reset_token": token})
    .then((res) => {
      setIsFetching(false);
      history.push("/login");
    })
    .catch( err => {
      setIsFetching(false);
    })
};

export const getCurrentProduct = (id, setCurrentRadioForstamt, setCurrentRevier, setCurrentWoodType, setBereitstellung, setInputHolzlange, setInputAmount, setInputPreis) => {
  axios.get(`/api/products/${id}` )
    .then((res) => {
      const {data} = res;
      setCurrentRadioForstamt(data.revier.forstamt);
      setCurrentRevier(data.revier);
      setCurrentWoodType(data.wood_type);
      setBereitstellung(data.bereitstellung);
      setInputHolzlange(!data.length ? "" : data.length );
      setInputAmount(data.amount);
      setInputPreis(data.price_per_fm);
    })
};

export const productUpdate = (id, currentWoodType, bereitstellung, inputHolzlange, inputPreis, inputAmount, dispatch) => {
  axios.put(`/api/products/${id}/update`,
    {
      wood_type_id: currentWoodType.id,
      bereitstellung: bereitstellung.name,
      length: inputHolzlange,
      price_per_fm: inputPreis,
      amount: inputAmount,
    }
  )
    .then((res) => {
      dispatch(getProducts());
    })
};

export const productCreate = (id, name, inputHolzlange, inputPreis, inputAmount, dispatch) => {
  axios.post('/api/products/create',
    {
      wood_type_id: id,
      bereitstellung: name,
      length: inputHolzlange,
      price_per_fm: inputPreis,
      amount: inputAmount,
    })
    .then((res) => {
      dispatch(getProducts());
    })
};

export const productDelete = (id, setCurrentProduct, dispatch) => {
  axios.delete(`/api/products/${id}/delete`)
    .then((res) => {
      dispatch(getProducts());
      setCurrentProduct("");
    })
};

export const statusUpdateById = (id, statusText, setStatusText, setIsEditeble, dispatch) => {
  axios.put(`/api/statuses/${id}/update`, {"name": statusText})
    .then((res) => {
      dispatch(getStatuses());
      setStatusText("");
      setIsEditeble(false);
    })
};

export const getCurrentCustomer = (id, setCustomer, setOrderHistory, paginate={}) => {
  const { current_page, per_page } = paginate;
  axios.get(`/api/customers/${id}`, {params: {page: current_page, per_page}} )
    .then((res) => {
      const {customer, order_history} = res.data;
      setCustomer(customer);
      setOrderHistory(order_history);
    })
};

export const customerUpdate = (id, customer, dispatch, history) => {
  axios.put(`/api/customers/${id}/update`,
    {
      addressing: customer.addressing,
      name: customer.name,
      surname: customer.surname,
      street_and_house: customer.street_and_house,
      post_code: customer.post_code,
      city: customer.city,
      email: customer.email,
      telephone: customer.telephone
    },
  )
    .then((res) => {
      dispatch(getCustomers());
      history.push("/kunden");
    })
};

export const customersSearch = (searchQuery, setCustomersArr) => {
  axios.get(`/api/customers/search`, { params: {"full_name": searchQuery} } )
    .then((res) => {
      setCustomersArr(res.data.data);
    })
};

export const getComments = (id, setComments, setIsFetching) => {
  setIsFetching(true);
  axios.get(`/api/customers/${id}/comments`, )
    .then((res) => {
      setComments(res.data);
      setIsFetching(false);
    })
    .catch((error) => {
      setIsFetching(false);
    });
};

export const commentDelete = (id, params, setComments, setIsFetching, dispatch) => {
  axios.delete(`/api/customers/comments/${id}/delete`)
    .then((res) => {
      getComments(params.id, setComments, setIsFetching);
      dispatch(getCustomers());
    })
};

export const addNewComment = (params, newCommentText, setComments, setIsFetching, setNewCommentText, dispatch) => {
  setIsFetching(true);
  axios.post(`/api/customers/${params.id}/comment`, {"content": newCommentText} )
    .then(() => {
      getComments(params.id, setComments, setIsFetching);
      dispatch(getCustomers());
      setNewCommentText("");
      setIsFetching(false);
    })
    .catch((error) => {
      setIsFetching(false);
    });
};

export const getCurrentOrder = (id, setOrder, setSelectedDate, setTelephone, setEmail, setContactPerson, setAddress, setAmount, setRevier, setCustomerDetails, setStatus, setStatusTypes) => {
  axios.get(`/api/orders/${id}` )
    .then((res) => {
      const {order, statuses} = res.data;
      setStatusTypes(statuses);
      setOrder(order);
      setAmount(order.amount);
      setRevier(order.revier);
      setCustomerDetails(order.customer);
      setStatus(order.status);
      if(order.appointment === null) return;
      if(order.appointment === undefined) return;
      setSelectedDate(new Date(order.appointment.datetime));
      setTelephone(order.appointment.telephone);
      setEmail(order.appointment.email);
      setContactPerson(order.appointment.contact_person);
      setAddress(order.appointment.address);

    })
};

export const orderUpdate = (id, amount, status, selectedDate, telephone, email, contactPerson, address, history, dispatch) => {
  axios.put(`/api/orders/${id}/update`,
    {
      amount: amount.toString(),
      status: status.id,
      appointment: {
        datetime: moment(new Date(selectedDate)).format("YYYY-MM-DD hh:mm:ss"),
        telephone: telephone,
        email: email,
        contact_person: contactPerson,
        address: address
      }
    },
  )
    .then((res) => {
      dispatch(getOrders());
      history.push("/bestellungen")
    })
};

export const getOrderComments = (id, setComments) => {
  axios.get(`/api/orders/${id}/comments` )
    .then((res) => {
      setComments(res.data);
    })
};

export const orderCommentDelete = (id, params, setComments) => {
  axios.delete(`/api/orders/comments/${id}/delete` )
    .then((res) => {
      getOrderComments(params.id, setComments);
    })
};

export const addOrderNewComment = (params, newCommentText, setNewCommentText, setComments) => {
  axios.post(`/api/orders/${params.id}/comment`, {"content": newCommentText}, )
    .then((res) => {
      getOrderComments(params.id, setComments);
      setNewCommentText("");
    })
};

export const ordersSearch = (searchQuery, setOrdersArr, setPaginationInfo) => {
  axios.get(`/api/orders/search`, {params: {"full_name": searchQuery} } )
    .then((res) => {
      setPaginationInfo({
        current_page: res.data.current_page,
        per_page: res.data.per_page,
        total: res.data.total
      });
      setOrdersArr(res.data.data);
    })
};

export const orderDelete = (id, setCurrentOrder, dispatch) => {
  axios.delete(`/api/orders/${id}/delete` )
    .then((res) => {
      dispatch(getOrders());
      setCurrentOrder("");
    })
};

export const orderFilter = (selectedStatus, selectedRevier, selectedCustomer, selectedHolzart, selectedBereitstellung, setOrdersArr, setOrdersData, setLoading, page, per_page) => {
  setLoading(true)
  axios.get(`/api/orders/filter?page=${page}`, {
    params: {
      "per_page": per_page,
      "status_id": selectedStatus.map(el => el.id),
      "revier_id": selectedRevier.map(el => el.id),
      "customer_id": selectedCustomer.map(el => el.id),
      "bereitstellung": selectedBereitstellung.map(el => el.name),
      "wood_type": selectedHolzart.map(el => el.name),
    }
  })
    .then((res) => {
      setOrdersData(res.data)
      setOrdersArr(res.data.data);
      setLoading(false)
      return res.data
    })
    .catch(err => setLoading(false))
};

export const getCurrentTemplate = (id, setTemplateText, setIsFetching) => {
  axios.get(`/api/mail-templates/${id}`, )
    .then((res) => {
      //setCurrentTemplate(res.data);
      setTemplateText(res.data.content);
      setIsFetching(false);
    })
};

export const saveCurrentTemplate = (id, templateText, setTemplateText, setIsFetching) => {
  setIsFetching(true);
  axios.put(`/api/mail-templates/${id}/update`, {content: templateText} )
    .then((res) => {
      getCurrentTemplate(id, setTemplateText, setIsFetching);
      setIsFetching(false);
    })
};

export const getSales = (selectedForstamt, selectedRevier, selectedProduct, date, selectedDateOption, setChartData, setTableData) => {
  axios.post(`/api/sales`, {
      "forstamt": selectedForstamt.id || "",
      "revier": selectedRevier.id || "",
      "product": selectedProduct.id || "",
      "from": date[0] ? `${date[0]}` : "",
      "to":   date[1] ? `${date[1]}` : "",
      "step": selectedDateOption.name || "",
    },
  )
    .then((res) => {
      setChartData(res.data.plot.sort((a, b) => new Date(a.date) - new Date(b.date)));
      setTableData({topSellers: res.data.topSellers, topEarners: res.data.topEarners});
    })
};

export const getCurrentForstamt = (axiosToken = false, id, setCurrentForstamt ) => {
  axios.get(`/api/forstamts/${id}`,{ axiosToken })
    .then((res) => {
      setCurrentForstamt({ ...res.data, email: res.data.user.email })
    })
    .catch((error) => {
      if (axios.isCancel(error)) return false
    });
};

export const forstamtUpdate = (id, currentForstamt, setCurrentForstamt) => {
  const {name, email} = currentForstamt;
  axios.put(`/api/forstamts/${id}/update`, {name, email} )
    .then(() => {
      getCurrentForstamt(false ,currentForstamt.id, setCurrentForstamt);
    })
};

export const statusCreate = (newStatusText, color, dispatch, setNewStatusText) => {
  axios.post(`/api/statuses/create`, {"name": newStatusText, "color": color})
    .then((res) => {
      dispatch(getStatuses());
      setNewStatusText("");
    })
};

export const woodTypesCreate = (currentRevier, newWoodType, setCurrentRevier, setNewWoodType, dispatch) => {
  axios.post(`/api/wood-types/create`, {"revier_id": currentRevier.id, "name": newWoodType})
    .then((res) => {
      dispatch(getReviersByForstamt(1));
      setCurrentRevier(currentRevier);
      dispatch(getWoodTypes());
      setNewWoodType("");
    })
};
