import { ActionType } from '../actionTypes';

const initialState = {
  reviersByForstamt: [],
  isFetchingRBF: false,
  errorRBF: false,
  errorMessageRBF: '',
};

export default function getReviersByForstamtReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_REVIERS_BY_FORSTAMT_START:
      return {...state, isFetchingRBF: true};
    case ActionType.GET_REVIERS_BY_FORSTAMT_SUCCESS:
      return {...state, reviersByForstamt: action.data, isFetchingRBF: false };
    case ActionType.GET_REVIERS_BY_FORSTAMT_FAILURE:
      return {...state, errorRBF: action.error, errorMessageRBF: action.errorMessage, isFetchingRBF: false };

    default:
      return state;
  }
}