export const ActionType = {
  LOG_IN_START: "LOG_IN_START",
  LOG_IN_SUCCESS: "LOG_IN_SUCCESS",
  LOG_IN_FAILURE: "LOG_IN_FAILURE",

  GET_PRODUCTS_START: "GET_PRODUCTS_START",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  GET_CUSTOMERS_START: "GET_CUSTOMERS_START",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",

  GET_ORDERS_START: "GET_ORDERS_START",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",

  GET_REVIERS_START: "GET_REVIERS_START",
  GET_REVIERS_SUCCESS: "GET_REVIERS_SUCCESS",
  GET_REVIERS_FAILURE: "GET_REVIERS_FAILURE",

  GET_WOODTYPES_START: "GET_WOODTYPES_START",
  GET_WOODTYPES_SUCCESS: "GET_WOODTYPES_SUCCESS",
  GET_WOODTYPES_FAILURE: "GET_WOODTYPES_FAILURE",

  GET_STATUSES_START: "GET_STATUSES_START",
  GET_STATUSES_SUCCESS: "GET_STATUSES_SUCCESS",
  GET_STATUSES_FAILURE: "GET_STATUSES_FAILURE",

  GET_MAILTEMPLATES_START: "GET_MAILTEMPLATES_START",
  GET_MAILTEMPLATES_SUCCESS: "GET_MAILTEMPLATES_SUCCESS",
  GET_MAILTEMPLATES_FAILURE: "GET_MAILTEMPLATES_FAILURE",

  GET_FORSTAMTS_START: "GET_FORSTAMTS_START",
  GET_FORSTAMTS_SUCCESS: "GET_FORSTAMTS_SUCCESS",
  GET_FORSTAMTS_FAILURE: "GET_FORSTAMTS_FAILURE",

  GET_REVIERS_BY_FORSTAMT_START: "GET_REVIERS_BY_FORSTAMT_START",
  GET_REVIERS_BY_FORSTAMT_SUCCESS: "GET_REVIERS_BY_FORSTAMT_SUCCESS",
  GET_REVIERS_BY_FORSTAMT_FAILURE: "GET_REVIERS_BY_FORSTAMT_FAILURE",

  GET_PRODUCTS_BY_REVIER_START: "GET_PRODUCTS_BY_REVIER_START",
  GET_PRODUCTS_BY_REVIER_SUCCESS: "GET_PRODUCTS_BY_REVIER_SUCCESS",
  GET_PRODUCTS_BY_REVIER_FAILURE: "GET_PRODUCTS_BY_REVIER_FAILURE",

  SET_ORDERS_FILTERS_SELECTED_REVIER: "SET_ORDERS_FILTERS_SELECTED_REVIER",
  SET_ORDERS_FILTERS_SELECTED_STATUS: "SET_ORDERS_FILTERS_SELECTED_STATUS",
  SET_ORDERS_FILTERS_SELECTED_BEREITSTELLUNG:
    "SET_ORDERS_FILTERS_SELECTED_BEREITSTELLUNG",
  SET_ORDERS_FILTERS_SELECTED_HOLZART: "SET_ORDERS_FILTERS_SELECTED_HOLZART",
  SET_ORDERS_FILTERS_SELECTED_CUSTOMER: "SET_ORDERS_FILTERS_SELECTED_CUSTOMER",
};
