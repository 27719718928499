import React from "react";

import {TextField, Checkbox, Grid,} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function FilterSelect( {options, label, setter, value, xs, md, lg} ) {

  const handleChange = (e, option) => {
    let newArr = option.reduce((acc, el) => {
      return [...acc.filter(item => item.id !== el.id), el];
    }, []);
    setter(newArr)
  };

  return (
    <Grid container item xs={xs} md={md} lg={lg} direction="row" wrap="nowrap" >
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        multiple
        style={{flex: 1, marginRight: 10}}
        value={value}
        options={options}
        onChange={handleChange}
        getOptionLabel={option => option.name ? option.name : option["full_name"]}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              color="primary"
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected || value.map(el=>el.id).includes(option.id)}
            />
            {option.name ? option.name : option["full_name"]}
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            fullWidth
          />
        )}
      />
    </Grid>
  )
}