import { ActionType } from '../actionTypes';
import axios from "axios";

export function logInStart() {
  return {
    type: ActionType.LOG_IN_START,
  }
}
export function logInSuccess(data) {
  return {
    type: ActionType.LOG_IN_SUCCESS,
    data
  }
}
export function logInFailure(error, errorMessage) {
  return {
    type: ActionType.LOG_IN_FAILURE,
    error,
    errorMessage
  }
}

export const logIn = (logInInfo) => {
  return dispatch => {
    dispatch(logInStart());
    axios.post(`/api/login`, logInInfo)
      .then(res => {
        localStorage.setItem("token", res.data.jwt.access_token);
        dispatch(logInSuccess(res.data.user));
      })
      .catch(error => {
        dispatch(logInFailure(true, (error?.response?.data || error.message)));
      });
  }
};