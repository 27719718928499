import { ActionType } from '../actionTypes';
import axios from "axios";

export function getReviersByForstamtStart() {
  return {
    type: ActionType.GET_REVIERS_BY_FORSTAMT_START,
  }
}
export function getReviersByForstamtSuccess(data) {
  return {
    type: ActionType.GET_REVIERS_BY_FORSTAMT_SUCCESS,
    data
  }
}
export function getReviersByForstamtFailure(error, errorMessage) {
  return {
    type: ActionType.GET_REVIERS_BY_FORSTAMT_FAILURE,
    error,
    errorMessage
  }
}

export const getReviersByForstamt = (id, axiosToken) => {
  return dispatch => {
    dispatch(getReviersByForstamtStart());
    axios.get(`/api/forstamts/${id}/reviers`, {
      axiosToken,
    })
      .then((res) => {
        dispatch(getReviersByForstamtSuccess(res.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return false;
        }
        dispatch(getReviersByForstamtFailure(true, (error?.response?.data || error.message)));
      });
  }
};