import jwtDecode from 'jwt-decode';

export const checkTokenExpiration = () => {
  try {
    const token = localStorage.getItem("token");
    if (jwtDecode(token).exp < Date.now().valueOf() / 1000) {
      return 'expired';
    }
    return token;
  } catch(err) {
    return null;
  }
};

// roles: ["admin", "forstamt", "revier"]