import { ActionType } from '../actionTypes';

const initialState = {
  reviers: [],
  isFetchingReviers: false,
  errorReviers: false,
  errorMessageReviers: '',
};

export default function getReviersReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_REVIERS_START:
      return {...state, isFetchingReviers: true};
    case ActionType.GET_REVIERS_SUCCESS:
      return {...state, reviers: action.data, isFetchingReviers: false };
    case ActionType.GET_REVIERS_FAILURE:
      return {...state, errorReviers: action.error, errorMessageReviers: action.errorMessage, isFetchingReviers: false };

    default:
      return state;
  }
}