import { ActionType } from '../actionTypes';

const initialState = {
  woodTypes: [],
  isFetchingWoodTypes: false,
  errorWoodTypes: false,
  errorMessageWoodTypes: '',
};

export default function getWoodTypesReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_WOODTYPES_START:
      return {...state, isFetchingWoodTypes: true};
    case ActionType.GET_WOODTYPES_SUCCESS:
      return {...state, woodTypes: action.data, isFetchingWoodTypes: false };
    case ActionType.GET_WOODTYPES_FAILURE:
      return {...state, errorWoodTypes: action.error, errorMessageWoodTypes: action.errorMessage, isFetchingWoodTypes: false };

    default:
      return state;
  }
}