import { ActionType } from '../actionTypes';

const initialState = {
  statuses: [],
  isFetchingStatuses: false,
  errorStatuses: false,
  errorMessageStatuses: '',
};

export default function getStatusesReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_STATUSES_START:
      return {...state, isFetchingStatuses: true};
    case ActionType.GET_STATUSES_SUCCESS:
      return {...state, statuses: action.data, isFetchingStatuses: false };
    case ActionType.GET_STATUSES_FAILURE:
      return {...state, errorStatuses: action.error, errorMessageStatuses: action.errorMessage, isFetchingStatuses: false };

    default:
      return state;
  }
}