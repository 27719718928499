import { ActionType } from '../actionTypes';

const initialState = {
  productsByRevier: [],
  isFetchingPBR: false,
  errorPBR: false,
  errorMessagePBR: '',
};

export default function getProductsByRevierReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_PRODUCTS_BY_REVIER_START:
      return {...state, isFetchingPBR: true};
    case ActionType.GET_PRODUCTS_BY_REVIER_SUCCESS:
      return {...state, productsByRevier: action.data, isFetchingPBR: false };
    case ActionType.GET_PRODUCTS_BY_REVIER_FAILURE:
      return {...state, errorPBR: action.error, errorMessagePBR: action.errorMessage, isFetchingPBR: false };

    default:
      return state;
  }
}