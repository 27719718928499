import { ActionType } from '../actionTypes';

const initialState = {
  userLogIn: {},
  isFetchingLogIn: false,
  errorLogIn: false,
  errorMessageLogIn: '',
};

export default function getWoodTypesReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.LOG_IN_START:
      return {...state, isFetchingLogIn: true};
    case ActionType.LOG_IN_SUCCESS:
      return {...state, userLogIn: action.data, isFetchingLogIn: false };
    case ActionType.LOG_IN_FAILURE:
      return {...state, errorLogIn: action.error, errorMessageLogIn: action.errorMessage, isFetchingLogIn: false };

    default:
      return state;
  }
}