
import { ActionType } from '../actionTypes';

const initialState = {
  templates: [],
  isFetchingTemplates: false,
  errorTemplates: false,
  errorMessageTemplates: '',
};

export default function getMailTemplatesReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_MAILTEMPLATES_START:
      return {...state, isFetchingTemplates: true};
    case ActionType.GET_MAILTEMPLATES_SUCCESS:
      return {...state, templates: action.data, isFetchingTemplates: false };
    case ActionType.GET_MAILTEMPLATES_FAILURE:
      return {...state, errorTemplates: action.error, errorMessageTemplates: action.errorMessage, isFetchingTemplates: false };

    default:
      return state;
  }
}