import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Title from "../../components/Title";
import ProductLayout from "../../components/ProductLayout";
import {productCreate} from "../../helpers/api";

import {useDispatch, useSelector} from "react-redux";

export default function AddNew() {
  const dispatch = useDispatch();
  let history = useHistory();

  const { userLogIn } = useSelector(state => state.login);
  const { forstamts } = useSelector(state => state.forstamts);

  const [currentRadioForstamt, setCurrentRadioForstamt] = useState({});
  const [currentRevier, setCurrentRevier] = useState({});
  const [currentWoodType, setCurrentWoodType] = useState({});
  const [bereitstellung, setBereitstellung] = useState({});
  const [inputHolzlange, setInputHolzlange] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [inputPreis, setInputPreis] = useState("");

  useEffect(() => {
    if (userLogIn.id !== undefined && forstamts.length !== 0 ){
      setCurrentRadioForstamt(forstamts[0])
    }
  },[userLogIn, forstamts]);

  const handleSaveProduct = () => {
    productCreate(currentWoodType.id, bereitstellung.name, inputHolzlange, inputPreis, inputAmount, dispatch);

    setCurrentRadioForstamt([]);
    setCurrentRevier([]);
    setCurrentWoodType([]);
    setBereitstellung([]);
    setInputHolzlange("");
    setInputAmount("");
    setInputPreis("");
    history.push("/alle-produkte")
  };

  return (
    <>
      <Title>Neuen Artikel hinzufügen</Title>
      <ProductLayout
        currentRadioForstamt={currentRadioForstamt}
        setCurrentRadioForstamt={setCurrentRadioForstamt}
        currentRevier={currentRevier}
        setCurrentRevier={setCurrentRevier}
        currentWoodType={currentWoodType}
        setCurrentWoodType={setCurrentWoodType}
        bereitstellung={bereitstellung}
        setBereitstellung={setBereitstellung}
        inputHolzlange={inputHolzlange}
        setInputHolzlange={setInputHolzlange}
        inputAmount={inputAmount}
        setInputAmount={setInputAmount}
        inputPreis={inputPreis}
        setInputPreis={setInputPreis}
        handleSubmit={handleSaveProduct}
        buttonText="Artikel hinzufügen"
      />
    </>
  );
}
