import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

import {Button, FormControl, Paper, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useDispatch, useSelector} from 'react-redux';
import {checkTokenExpiration} from "../../helpers/token";
import {logIn} from "../../redux/actions/logInAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
    width: 364,
    [theme.breakpoints.down('xs')]: {
      width:"100%",
    },
  },
  input: {
    width: "100%",
    minWidth: 240,
  },
}));

const LogInForm = ({history}) => {
  const { isFetchingLogIn } = useSelector(state => state.login);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [logInInfo, setLogInInfo] = useState({name:"",password:""});

  useEffect(()=>{
    const token = checkTokenExpiration();
    if (token !== null && token !== "expired") {
      history.push("/bestellungen")
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(logIn(logInInfo));
  };

  return (
    <Paper className={classes.paper}>
      <FormControl style={{width:"100%"}} component="form" onSubmit={handleSubmit} >
        <TextField
          fullWidth
          className={classes.input}
          variant="outlined"
          label="Benutzername"
          type="text"
          name={"name"}
          value={logInInfo.name}
          onChange={(e)=>setLogInInfo({...logInInfo, name: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          className={classes.input}
          variant="outlined"
          label="Passwort"
          type="password"
          name={"password"}
          value={logInInfo.password}
          onChange={(e)=>setLogInInfo({...logInInfo, password: e.target.value})}
          margin="normal"
        />
        <div style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginTop: 16}}>
          <Link className={classes.forgotPass} to="/login/reset" >Passwort vergessen?</Link>
          <Button disabled={isFetchingLogIn} type="submit" variant="contained" color="primary" >{isFetchingLogIn ? "Wird geladen" : "Anmelden"}</Button>
        </div>
      </FormControl>
    </Paper>
  );
};

export default LogInForm;
