import React, {useState} from "react";

import {Button, Grid, IconButton, SvgIcon, TextField, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

import {useDispatch} from "react-redux";
import {statusUpdateById} from "../helpers/api";

const useStyles = makeStyles(theme => ({
  textField: {
    width:"50%",
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      width:"80%",
    },
  },
}));

export default function OrderStatusItem({ el }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isEditeble, setIsEditeble] = useState(false);
  const [statusText, setStatusText] = useState(el.name);

  const editStatus = (e) => {
    e.preventDefault();
    statusUpdateById(el.id, statusText, setStatusText, setIsEditeble, dispatch);
  };

  return (
    <Grid item key={el.id} xs={12} sm={6} lg={4} >
      {!isEditeble
        ? <>
            <Typography component="span" style={{color: "#fff",backgroundColor: `${el.color}`, borderRadius: 4,padding:3}}>{el.name}</Typography>
            <IconButton style={{marginLeft: 20}} onClick={() => setIsEditeble(true)}>
              <SvgIcon color="action">
                <path
                  d="M16.84,2.73C16.45,2.73 16.07,2.88 15.77,3.17L13.65,5.29L18.95,10.6L21.07,8.5C21.67,7.89 21.67,6.94 21.07,6.36L17.9,3.17C17.6,2.88 17.22,2.73 16.84,2.73M12.94,6L4.84,14.11L7.4,14.39L7.58,16.68L9.86,16.85L10.15,19.41L18.25,11.3M4.25,15.04L2.5,21.73L9.2,19.94L8.96,17.78L6.65,17.61L6.47,15.29"/>
              </SvgIcon>
            </IconButton>
          </>
        :
          <form onSubmit={editStatus} >
            <TextField
              label="Bearbeiten status"
              className={classes.textField}
              type="text"
              value={statusText}
              variant="outlined"
              onChange={(e)=>setStatusText(e.target.value.slice(0, 255))}
            />
            <Button type="submit" variant="outlined" color="primary" >Bearbeiten</Button>
          </form>
      }
    </Grid>
  );
}