import Autocomplete from "@material-ui/lab/Autocomplete";
import {CircularProgress, Grid, TextField} from "@material-ui/core";
import React from "react";

export default function GridAutocomplete({options, value, onChange, label, disabled}) {

  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  return (
    <Grid container item xs={12} md={6} lg={4} direction="row" wrap="nowrap" >
      <Autocomplete
        disableClearable
        disabled={disabled}
        loading={loading}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        style={{flex: 1, marginRight: 10}}
        options={options}
        value={value}
        onChange={(e,option) => onChange(option)}
        getOptionLabel={option => option.name || ""}
        renderOption={option => option.name}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Grid>
  )
}