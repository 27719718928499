import React from 'react';
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";

import {AppBar, IconButton, Toolbar,CardMedia} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "../assets/img/HessenForst.jpg";
import Button from "@material-ui/core/Button";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  media: {
    width: 140,
    [theme.breakpoints.up('sm')]: {
      display: "none",
    },
  },
}));

export default function Header({ handleDrawerToggle }) {
  const classes = useStyles();
  let history = useHistory();

  const handleLogOut = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar style={{display: "flex", justifyContent: "space-between"}} >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" >
          <CardMedia className={classes.media} component="img" src={Logo} />
        </Link>

        <div >
          <Button variant="contained" color="primary" onClick={handleLogOut} >Ausloggen</Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};