import React from "react";

import {Grid, Typography,} from '@material-ui/core';

export default function GridElement({sub, text}) {

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" component="p">{sub}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography color="textSecondary" variant="body2" component="p">{text}</Typography>
      </Grid>
    </Grid>
  );
}