import { ActionType } from '../actionTypes';
import {getHelper} from "./getHelper";

export function getWoodTypesStart() {
  return {
    type: ActionType.GET_WOODTYPES_START,
  }
}
export function getWoodTypesSuccess(data) {
  return {
    type: ActionType.GET_WOODTYPES_SUCCESS,
    data
  }
}
export function getWoodTypesFailure(error, errorMessage) {
  return {
    type: ActionType.GET_WOODTYPES_FAILURE,
    error,
    errorMessage
  }
}

export const getWoodTypes = () => {
  return dispatch => {
    getHelper('/api/wood-types', getWoodTypesStart, getWoodTypesSuccess, getWoodTypesFailure, dispatch);
  }
};