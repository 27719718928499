import { ActionType } from '../actionTypes';
import {getHelper} from "./getHelper";

export function getReviersStart() {
  return {
    type: ActionType.GET_REVIERS_START,
  }
}
export function getReviersSuccess(data) {
  return {
    type: ActionType.GET_REVIERS_SUCCESS,
    data
  }
}
export function getReviersFailure(error, errorMessage) {
  return {
    type: ActionType.GET_REVIERS_FAILURE,
    error,
    errorMessage
  }
}

export const getReviers = () => {
  return dispatch => {
    getHelper('/api/reviers', getReviersStart, getReviersSuccess, getReviersFailure, dispatch);
  }
};