import React, {useEffect, useState, useCallback} from "react";
import BASE_URL from "../../helpers/url";

import {Button, Grid, Paper} from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import { makeStyles } from "@material-ui/core/styles";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer} from 'recharts';

import Title from "../../components/Title";
import Table from "../../components/Table/Table";
import GridAutocomplete from "../../components/GridAutocomplete";

import {useDispatch, useSelector} from "react-redux";
import {getReviersByForstamt} from "../../redux/actions/getReviersByForstamtAction";
import {getProductsByRevier} from "../../redux/actions/getProductsByRevierAction";
import {chartMockData} from "../../helpers/data";
import {getSales} from "../../helpers/api";
import {checkTokenExpiration} from "../../helpers/token";

const useStyles = makeStyles(theme => ({
  link: {
    marginLeft: theme.spacing(4),
    textDecoration: "none",
  },
  side: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
  },
}));

const forstamtHeadCells = [
  { id: 'name', label: 'Top Verkäufe', redirectPage:'berichte', page: 'einstellungen' },
  { id: 'sales', label: '' },
];
const productHeadCells = [
  { id: 'name', label: 'Top Umsätze', redirectPage:'berichte', page: 'alle-produkte' },
  { id: 'sales', label: '' },
];

export default function Reports() {
  const { userLogIn } = useSelector(state => state.login);
  const { forstamts } = useSelector(state => state.forstamts);
  const { reviersByForstamt } = useSelector(state => state.reviersByForstamt);
  const { productsByRevier } = useSelector(state => state.productsByRevier);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [selectedForstamt, setSelectedForstamt] = useState([]);
  const [selectedRevier, setSelectedRevier] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedDateOption, setSelectedDateOption] = useState([]);
  const [date, setDate] = useState("");

  const [chartData, setChartData] = useState(chartMockData);
  const [paginationInfo, setPaginationInfo] = useState({current_page: 1, per_page: 10, total: 10});
  const [tableData, setTableData] = useState({topSellers: [],topEarners:[]});

  const handleFilter = useCallback(() => {
    if (date == null) return false;
    getSales(selectedForstamt, selectedRevier, selectedProduct, date, selectedDateOption, setChartData, setTableData);
  }, [date, selectedDateOption, selectedProduct, selectedRevier, selectedForstamt]);

  useEffect(()=>{
    if (userLogIn.id !== undefined) handleFilter();
  },[userLogIn.id]);

  const handleClearFilter = () => {
    setSelectedForstamt([]);
    setSelectedRevier([]);
    setSelectedProduct([]);
    setSelectedDateOption([]);
    setDate("");
    handleFilter();
  };

  const handleForstamtFilter = (option) => {
    setSelectedForstamt(option);
    dispatch(getReviersByForstamt(option.id));
  };
  const handleRevierFilter = (option) => {
    setSelectedRevier(option);
    dispatch(getProductsByRevier([option.id]));
  };
  const handleProductFilter = (option) => {
    setSelectedProduct(option);
  };
  const handleSetDate = (value) => {
    if (value === null || value === undefined) setDate("");
    setDate(value)
  };

  let exportForstamt = selectedForstamt.id ? `&forstamt=${selectedForstamt.id}` : "";
  let exportRevier = selectedRevier.id ? `&revier=${selectedRevier.id}` : "";
  let exportProduct = selectedProduct.id ? `&product=${selectedProduct.id}` : "";
  let exportDateFrom = date && date[0] ? `&from=${date[0]}` : "";
  let exportDateTo = date && date[1] ? `&to=${date[1]}` : "";
  let exportDateStep = selectedDateOption.name ? `&step=${selectedDateOption.name}` : "";

  return (
    <>
      <Title >
        Berichte
        <Button
          className={classes.link}
          variant="outlined"
          color="primary"
          href={`${BASE_URL}/api/report/export?token=${checkTokenExpiration()}${exportForstamt}${exportRevier}${exportProduct}${exportDateFrom}${exportDateTo}${exportDateStep}`}
        >Exportieren</Button>
      </Title>

      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center" className={classes.side}>

        <GridAutocomplete
          options={forstamts}
          value={selectedForstamt}
          onChange={handleForstamtFilter}
          label={"Forstamt suchen..."}
        />
        <GridAutocomplete
          options={reviersByForstamt}
          value={selectedRevier}
          onChange={handleRevierFilter}
          label={"Revier suchen..."}
          disabled={selectedForstamt.length === 0}
        />
        <GridAutocomplete
          options={productsByRevier}
          value={selectedProduct}
          onChange={handleProductFilter}
          label={"Artikel suchen..."}
          disabled={selectedRevier.length === 0}
        />
        <GridAutocomplete
          options={[{name:"Jahr", value:"decade"},{name:"Monat", value:"year"},{name:"Tag", value:"month"}]}
          value={selectedDateOption}
          onChange={setSelectedDateOption}
          label={"Datum auswählen..."}
        />
        <Grid container item xs={12} md={6} lg={4} direction="row" wrap="nowrap" >
          <DateRangePicker
            className="reports_dateRangePicker"
            maxDetail={selectedDateOption.value}
            minDetail={selectedDateOption.value}
            maxDate={new Date()}
            onChange={handleSetDate}
            value={date}
            calendarIcon={<EventIcon color="action"/>}
          />
        </Grid>
        <Grid container item xs={12} md={6} lg={4} direction="row" wrap="nowrap" >
          <Button size="large" variant="outlined" color="primary" onClick={handleFilter} >
            Filter
          </Button>
          <Button style={{marginLeft:15}} size="large" variant="outlined" color="secondary" onClick={handleClearFilter} >
            Löschen
          </Button>
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
          >
            <XAxis dataKey="date" stroke="#BDBDBD" />
            <YAxis axisLine={false} unit=' &euro;' stroke="#BDBDBD" />
            <CartesianGrid vertical={false} stroke="#BDBDBD" />
            <Line dataKey="sales" stroke="#0073AA" />
            {/*<Line dataKey="amount" stroke="#0073AA" />*/}
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Table
            smallTable
            headCells={forstamtHeadCells}
            tableElements={tableData.topSellers}
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Table
            smallTable
            headCells={productHeadCells}
            tableElements={tableData.topEarners}
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
          />
        </Grid>
      </Grid>
    </>
  );
}
