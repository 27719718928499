import { ActionType } from '../actionTypes';

const initialState = {
  customers: [],
  isFetchingCustomers: false,
  errorCustomers: false,
  errorMessageCustomers: '',
};

export default function getCustomersReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_CUSTOMERS_START:
      return {...state, isFetchingCustomers: true};
    case ActionType.GET_CUSTOMERS_SUCCESS:
      return {...state, customers: action.data, isFetchingCustomers: false };
    case ActionType.GET_CUSTOMERS_FAILURE:
      return {...state, errorCustomers: action.error, errorMessageCustomers: action.errorMessage, isFetchingCustomers: false };

    default:
      return state;
  }
}