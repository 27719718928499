import React, {useEffect, useState} from "react";

import Title from "../../components/Title";
import Table from "../../components/Table/Table";

import {Button, CircularProgress, Grid, TextField,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useDispatch, useSelector} from "react-redux";
import {getCustomers} from "../../redux/actions/getCustomersAction";
import {customersSearch} from "../../helpers/api";
import BASE_URL from "../../helpers/url";
import {checkTokenExpiration} from "../../helpers/token";

const headCells = [
  {id: 'customers', label: 'Kunden', page: 'kunden'},
  {id: 'email', label: 'E-Mail'},
  {id: 'last_order', label: 'Letzte Bestellung', page:'bestellungen'},
  {id: 'total_orders', label: 'Gesamte Bestellungen'},
  {id: 'comments', label: 'Kommentare'},
];

const useStyles = makeStyles(theme => ({
  side: {
    marginTop: 50,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: "space-between",
  },
  textField: {
    marginRight: 10,
    marginBottom: 10,
    width: 350,
  },
  title: {
    display: "inline-flex",
  },
  link: {
    marginLeft: theme.spacing(4),
    textDecoration: "none",
  },
  fabProgress: {
    margin: "20% 50%",
  },
}));

export default function Customers() {
  const { userLogIn } = useSelector(state => state.login);
  const { customers, isFetchingCustomers } = useSelector(state => state.customers);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [paginationInfo, setPaginationInfo] = useState({current_page: 1, per_page: 10, total: 0});
  const [customersArr, setCustomersArr] = useState([]);
  //const [formattedCustomers, setFormattedCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (userLogIn.id !== undefined) dispatch(getCustomers());
  },[dispatch, userLogIn.id]);

  useEffect(() => {
    if (userLogIn.id !== undefined) dispatch(getCustomers({...paginationInfo}))
  },[userLogIn, paginationInfo, dispatch]);

  useEffect(() => {
    if (Object.keys(customers).length > 0) {
      setCustomersArr(customers.data);
      if (customers.current_page !== paginationInfo.current_page
        || customers.per_page !== paginationInfo.per_page
        || customers.total !== paginationInfo.total) {
        setPaginationInfo({current_page: customers.current_page, per_page: customers.per_page, total: customers.total});
      }
    }
  },[customers]);
  // useEffect(() => {
  //   const newCustomers = customersArr.map((el) => {
  //     if (el.last_order === null) return;
  //     const {id, name} = el.last_order;
  //     return {...el, last_order: `#${id} ${name}`}
  //   });
  //
  //   setFormattedCustomers(newCustomers);
  // }, [customersArr]);

  const handleSearch = (e) => {
    e.preventDefault();
    customersSearch(searchQuery, setCustomersArr);
    setSearchQuery("");
  };

  let exportCustomer = searchQuery.length > 0 ? `&customer=${searchQuery}` : "";

  return (
    <>
      {isFetchingCustomers
        ? (<CircularProgress size={68} className={classes.fabProgress}/>)
        : (<>
          <div className={classes.titleWrapper}>
            <Title myClasses={classes.title}>
              Kunden
              <Button
                className={classes.link}
                variant="outlined"
                color="primary"
                href={`${BASE_URL}/api/customers/export?token=${checkTokenExpiration()}${exportCustomer}`}
              >Exportieren</Button>
            </Title>
          </div>

          <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.side}>
            <form onSubmit={handleSearch} >
              <TextField
                label="Vor-und Nachname oder beides..."
                className={classes.textField}
                type="search"
                value={searchQuery}
                variant="outlined"
                onChange={(e)=>setSearchQuery(e.target.value)}
              />
              <Button type="submit" variant="outlined" color="primary" >
                Suche
              </Button>
            </form>
          </Grid>

          <Table
            headCells={headCells}
            tableElements={customersArr}
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
          />
        </>)
      }
    </>
  );
}
