import React, {useEffect, useState} from "react";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/de';

import {Button, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import Title from "../../components/Title";

import {getCurrentTemplate, saveCurrentTemplate} from "../../helpers/api";
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
  fabProgress: {
    margin: "20% 50%",
  },
}));

export default function EmailsTemplate({match: {params}}) {
  const { userLogIn } = useSelector(state => state.login);
  const classes = useStyles();

  //const [currentTemplate, setCurrentTemplate] = useState({});
  const [templateText, setTemplateText] = useState("");
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (userLogIn.id !== undefined) getCurrentTemplate(params.templateId, setTemplateText, setIsFetching);
  }, [params.templateId, userLogIn.id]);

  const handleSaveTemplate = () => {
    saveCurrentTemplate(params.templateId, templateText, setTemplateText, setIsFetching);
  };

  return (
    <>
      <Title style={{marginBottom:30}}>E-Mail Vorlagen</Title>

      {isFetching
        ? <CircularProgress size={68} className={classes.fabProgress}/>
        : <>
            <CKEditor
              editor={ ClassicEditor }
              data={ templateText }
              config={ {
                // Use the German language for this editor.
                language: 'de',
                wordCount: { displayWords: false, },
              } }
              onChange={ ( event, editor ) => {
                const data = editor.getData();
                //console.log( { event, editor, data } );
                setTemplateText(data);
              } }
            />
            <Button
            onClick={handleSaveTemplate}
            style={{marginTop:20}}
            variant="contained"
            color="primary"
            fullWidth
            >
              Speichern
            </Button>
          </>
      }
    </>
  );
}