import React, {useEffect, useState} from "react";

import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/de";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {
  Button, Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {makeStyles, useTheme} from "@material-ui/core/styles";

import Title from "../../components/Title";
import GridElement from "../../components/GridElement";

import {useDispatch, useSelector} from 'react-redux';
import {addOrderNewComment, getCurrentOrder, getOrderComments, orderCommentDelete, orderUpdate} from "../../helpers/api";

moment.locale("de");

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 4),
    margin: theme.spacing(4, 0),
  },
  selectField: {
    margin: theme.spacing(0),
  },
  card: {
    width: "95%",
    marginBottom: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  date: {
    padding: theme.spacing(0, 2),
  },
}));

export default function Order({history, match: {params}}) {
  const { userLogIn } = useSelector(state => state.login);
  const dispatch = useDispatch();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [order, setOrder] = useState({});
  const [statusTypes, setStatusTypes] = useState([]);
  const [status, setStatus] = useState({});
  const [amount, setAmount] = useState('');
  const [revier, setRevier] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [comments, setComments] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [address, setAddress] = useState("");
  const [newCommentText, setNewCommentText] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleDateChange = date => {
    if (date === undefined || date === null) return;
    setSelectedDate(date);
  };

  useEffect(() => {
    if(status.id === 2) setOpenModal(true);
    if(status.id === 3 && order.bereitstellung === "gerückt am Weg") {
      const mail = 'mailto:';
      let a = document.createElement('a');
      a.href = mail;
      a.click();
    }
  }, [status.id]);
  useEffect(() => {
    if (userLogIn.id !== undefined) {
      getCurrentOrder(params.id, setOrder, setSelectedDate, setTelephone, setEmail, setContactPerson, setAddress, setAmount, setRevier, setCustomerDetails, setStatus, setStatusTypes);
      getOrderComments(params.id, setComments);
    }
  }, [params.id, userLogIn.id]);

  const handleChangeOrder = (e) => {
    e.preventDefault();
    orderUpdate(params.id, amount, status, selectedDate, telephone, email, contactPerson, address, history, dispatch);
  };
  const handleDeleteComment = (id) => {
    orderCommentDelete(id, params, setComments);
  };
  const addNewComment = () => {
    addOrderNewComment(params, newCommentText, setNewCommentText, setComments);
  };

  const handleInputBlur = () => {
    let newInputAmount = amount - (amount % 5);
    setAmount(newInputAmount);
  };

  return (
    <form onSubmit={handleChangeOrder}>
      <Title>Bestellung #{params.id} {order.name}</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.root}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 20}}>
              <Typography variant="inherit" component="h2">Bestelldetails
                <IconButton style={{marginLeft: 20}} onClick={() => setIsEditable(!isEditable)}>
                  <SvgIcon color="action">
                    <path
                      d="M16.84,2.73C16.45,2.73 16.07,2.88 15.77,3.17L13.65,5.29L18.95,10.6L21.07,8.5C21.67,7.89 21.67,6.94 21.07,6.36L17.9,3.17C17.6,2.88 17.22,2.73 16.84,2.73M12.94,6L4.84,14.11L7.4,14.39L7.58,16.68L9.86,16.85L10.15,19.41L18.25,11.3M4.25,15.04L2.5,21.73L9.2,19.94L8.96,17.78L6.65,17.61L6.47,15.29"/>
                  </SvgIcon>
                </IconButton>
              </Typography>
              <Typography color="textSecondary" variant="subtitle2" component="p">{order.date}</Typography>
            </div>
            <Grid container spacing={3}>
              <GridElement sub={"Revier:"} text={revier.name}/>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">Bestellstatus:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    disabled={!isEditable}
                    disableClearable
                    className={classes.selectField}
                    onChange={(e, option) => setStatus(option)}
                    options={statusTypes}
                    value={status}
                    name={"status"}
                    getOptionLabel={option => option.name ? option.name : ""}
                    renderInput={params => (
                      <TextField {...params} label="Status" variant="outlined" fullWidth/>
                    )}
                    renderOption={(option, {inputValue}) => {
                      const matches = match(option.name, inputValue);
                      const parts = parse(option.name, matches);
                      return (
                        <div className={`status-id-${option.id}`}>
                          <IconButton size="small" style={{marginRight: 10}}>
                            <span style={{background: option.color, height: 10, width: 10, borderRadius: "50%"}}/>
                          </IconButton>
                          {parts.map((part, index) => (
                            <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                            {part.text}
                            </span>
                          ))}
                        </div>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <GridElement sub="Holzart:" text={order["wood_type"]}/>
              <GridElement sub="Preis/FM:" text={`€ ${order["price_per_fm"]}/Fm`}/>
              <GridElement sub="Gesamtpreis:" text={`€ ${order["total_price"]}`}/>
              <GridElement sub="Nur Abtransport:" text={order["nur_abtransport"] === 1 ? "Ja" : "Nein"}/>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">Menge:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    disabled={!isEditable}
                    value={amount}
                    fullWidth
                    variant="outlined"
                    label="Menge"
                    type="number"
                    inputProps={{ min: "0", step: "5" }}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => setAmount(e.target.value.slice(0, 8))}
                    onBlur={handleInputBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {status.id === 4 && <Paper className={classes.root}>
            <Typography variant="inherit" component="h2" gutterBottom>Terminvereinbarung</Typography>
            <Grid container spacing={3}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={4} md={2}>
                  <Typography variant="subtitle2" component="p">Datum:</Typography>
                </Grid>
                <Grid item xs={8} md={10}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de"}>
                    <KeyboardDateTimePicker
                      format="DD/MM/YYYY hh:mm"
                      cancelLabel={"Stornieren"}
                      okLabel={"in Ordnung"}
                      value={selectedDate}
                      onChange={handleDateChange}
                      //disabled
                    />
                  </MuiPickersUtilsProvider>

                  {/*<DateTimePicker*/}
                  {/*  className="reports_dateRangePicker"*/}
                  {/*  minDate={new Date()}*/}
                  {/*  onChange={handleDateChange}*/}
                  {/*  value={selectedDate}*/}
                  {/*  calendarIcon={<EventIcon color="action"/>}*/}
                  {/*/>*/}
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">Kontaktperson:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Kontaktperson"
                    type="text"
                    value={contactPerson}
                    onChange={(e)=>setContactPerson(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">E-mail Adresse:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="E-mail Adresse"
                    type="email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">Telefonnummer:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Telefonnummer"
                    type="tel"
                    value={telephone}
                    onChange={(e)=>setTelephone(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">Treffpunkt:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Treffpunkt"
                    type="text"
                    value={address}
                    onChange={(e)=>setAddress(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>}

          <Paper className={classes.root}>
            <Typography variant="inherit" component="h2" gutterBottom>Kundendaten</Typography>
            <Grid spacing={3} container justify="space-between" alignItems="center">
              <GridElement sub={"Anrede:"} text={customerDetails.addressing}/>
              <GridElement sub={"Straße/Hausnummer:"} text={customerDetails["street_and_house"]}/>
              <GridElement sub={"Kundenname:"} text={customerDetails["full_name"]}/>
              <GridElement sub={"Postleitzahl:"} text={customerDetails["post_code"]}/>
              <GridElement sub={"Kundennummer:"} text={customerDetails["customer_number"]}/>
              <GridElement sub={"Stadt:"} text={customerDetails.city}/>
              <GridElement sub={"E-mail:"} text={customerDetails.email}/>
              <GridElement sub={"Telefonnummer:"} text={customerDetails.telephone}/>
            </Grid>
          </Paper>
        </Grid>

        <Grid item container xs={12} lg={4} alignItems="stretch">
          <Paper className={classes.root} style={{width: "100%"}}>
            <Typography variant="inherit" component="h2" gutterBottom>Kommentare</Typography>
            <Grid container wrap="wrap" style={{position: "relative", minHeight: 500, height: "100%"}} alignItems="flex-end">
              <Grid item xs={12} style={{
                position: "absolute",
                top: 0,
                bottom: 255,
                overflowY: "scroll",
                marginBottom: 20,
                width: "100%"
              }}>
                {comments.map(el => {
                  return (
                    <div key={el.id} className={classes.card}>
                      <div className={classes.text}>
                        <Typography style={{wordWrap: "break-word"}} variant="body2" component="p" gutterBottom>
                          {el.content}
                        </Typography>
                      </div>
                      <div className={classes.date}>
                        <Typography variant="body2" color="textSecondary" component="p">hinzugefügt am {moment(el.created_at).format("MMMM DD, YYYY")}</Typography>
                        <Button color="secondary" size="small" onClick={() => handleDeleteComment(el.id)}>Kommentar löschen</Button>
                      </div>
                    </div>
                  )
                })}
              </Grid>

              <Grid item xs={12} style={{marginBottom: 65}}>
                <TextField
                  fullWidth
                  label="Kommentare"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={newCommentText}
                  onChange={(e) => setNewCommentText(e.target.value.slice(0, 500))}
                />
                <Button
                  style={{marginTop: 10}}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={addNewComment}
                >
                  Kommentar hinzufügen
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!isEditable}
      >
        Speichern
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        onClose={()=>setOpenModal(false)}
      >
        <DialogContent>
          <DialogContentText variant="h5">
            Bitte prüfen Sie ob für diesen Kunden ein Motorsägenschein vorliegt. Klicken Sie auf "OK" um fortzufahren.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>setOpenModal(false)} variant="contained" size="large" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}