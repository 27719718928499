import { ActionType } from '../actionTypes';

const initialState = {
  forstamts: [],
  isFetchingForstamts: false,
  errorForstamts: false,
  errorMessageForstamts: '',
};

export default function getForstamtsReducer(state = initialState, action) {

  switch (action.type) {
    case ActionType.GET_FORSTAMTS_START:
      return {...state, isFetchingForstamts: true};
    case ActionType.GET_FORSTAMTS_SUCCESS:
      return {...state, forstamts: action.data, isFetchingForstamts: false };
    case ActionType.GET_FORSTAMTS_FAILURE:
      return {...state, errorForstamts: action.error, errorMessageForstamts: action.errorMessage, isFetchingForstamts: false };

    default:
      return state;
  }
}