import React, {useState} from "react";
import {Link} from "react-router-dom";

import {
  //Checkbox,
  // IconButton,
  Paper, Table, TableBody, TableCell, TablePagination, TableRow, Button
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {red, green} from "@material-ui/core/colors";

import EnhancedTableHead from "./TableHead";
import {useSelector} from "react-redux";
import clsx from "clsx";
//import EnhancedTableToolbar from "./TableToolbar";

// function desc(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function stableSort(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// function getSorting(order, orderBy) {
//   return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4, 0)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableSmall: {
    minWidth: 450,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  editLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    //textTransform: "capitalize",
  },
  trashLink: {
    textTransform: "capitalize",
  },
}));

export default function EnhancedTable({ headCells, tableElements, handleTrash, smallTable=false, paginationInfo, setPaginationInfo }) {
  const { userLogIn } = useSelector(state => state.login);
  const { current_page, per_page, total, } = paginationInfo;

  const classes = useStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('price-fm');
  //const [selected, setSelected] = useState([]);
  const [elementsPerPage, setElementsPerPage] = useState(per_page ? per_page : 10);

  const handleRequestSort = (e, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = e => {
  //   if (e.target.checked) {
  //     const newSelecteds = tableElements.map(n => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (e, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleChangePage = (e, newPage) => {
    setPaginationInfo((prevState) => ({ ...prevState, current_page: newPage + 1 }));
  };

  const handleChangeElementsPerPage = e => {
    setElementsPerPage(parseInt(e.target.value, 10));
    setPaginationInfo((prevState) => ({ ...prevState, current_page: 1, per_page: parseInt(e.target.value, 10) }));
  };

  // const isSelected = id => selected.indexOf(id) !== -1;
  const emptyElements = elementsPerPage - Math.min(elementsPerPage, total - (current_page - 1) * elementsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table
            className={smallTable ? classes.tableSmall : classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              //numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              productCount={total}
              headCells={headCells}
            />
            <TableBody>
              {
                //stableSort(tableElements, getSorting(order, orderBy))
                //.slice((current_page - 1) * elementsPerPage, (current_page - 1)  * elementsPerPage + elementsPerPage)
                tableElements.map((item, index) => {
                  //const isItemSelected = isSelected(item.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      //onClick={e => handleClick(e, item.id)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={item.id}
                      //selected={isItemSelected}
                    >
                      {/*<TableCell padding="checkbox">*/}
                      {/*  <Checkbox*/}
                      {/*    checked={isItemSelected}*/}
                      {/*    inputProps={{ 'aria-labelledby': labelId }}*/}
                      {/*  />*/}
                      {/*</TableCell>*/}
                      {headCells.map((el, i) => {
                        if(el.id === 'name') {
                            if(el.page === "bestellungen" || el.page === "emails") {
                              return <TableCell key={i} style={{minWidth: 250}} component="th" id={labelId} scope="item" >
                                  <Link className={classes.editLink} to={`/${el.page}/${item.id}`}>
                                    {item.name}
                                  </Link>
                                </TableCell>
                            }
                            if(el.redirectPage === "berichte") {
                              return <TableCell align={'left'} className={classes.smallTable} key={i} component="th" id={labelId} scope="item" >
                                  <Link className={classes.editLink} to={`/${el.page}/${item.id}`}>
                                    {index+1}. {item.name}
                                  </Link>
                                </TableCell>
                            }
                            return <TableCell key={i} style={{minWidth: 250}} component="th" id={labelId} scope="item" >
                                  {userLogIn.role === "revier"
                                    ? item.name
                                    : (<>
                                        <Link className={classes.editLink} to={`/${el.page}/${item.id}`}>
                                          {item.name}
                                        </Link>
                                        <div className={classes.trashWrapper}>
                                          <Button component={Link} to={`/${el.page}/${item.id}`} className={clsx(classes.trashLink, classes.editLink)} color="primary" >Bearbeiten</Button>
                                          <Button className={classes.trashLink} color="secondary" onClick={() => handleTrash(item)}>Löschen</Button>
                                        </div>
                                      </>)
                                  }
                                </TableCell>;
                          }
                          if (el.id === 'customers') {
                            return <TableCell key={i} style={{minWidth: 250}} component="th" id={labelId} scope="item" >
                              <Link className={classes.editLink} to={`/${el.page}/${item.id}`}>#{item["customer_number"]} {item["full_name"]}</Link></TableCell>
                          }
                          if (el.id === 'article') {
                            return <TableCell key={i} component="th" id={labelId} scope="item" >
                              <Link className={classes.editLink} to={`/${el.page}/${item.id}`}>{item.article}</Link></TableCell>
                          }
                          if (el.id === 'customer') {
                            return <TableCell key={i} align="center" >
                              <Link className={classes.editLink} to={`/${el.page}/${item.customer_id}`}>{item.customer}</Link></TableCell>
                          }
                          if (el.id === 'sales') {
                            return <TableCell key={i} className={classes.smallTableSales} align="right">{item[el.id]}</TableCell>
                          }
                          if (el.id === 'last_order') {
                            if (item.last_order === null) return <TableCell key={i} align="center" />;
                            return <TableCell key={i} align="center" >
                              <Link className={classes.editLink} to={`/${el.page}/${item.last_order.id}`}>{`#${item.last_order.id} ${item.last_order.date}`}</Link></TableCell>
                          }
                          if (el.id === 'status') {
                            return <TableCell key={i} align="center"><p style={{color: "#fff",backgroundColor: `${item.status.color}`, borderRadius: 4,padding:3}}>{item.status.name}</p></TableCell>
                          }
                          if (el.id === 'stock') {
                            return <TableCell key={i} style={{color: parseFloat(item.amount) > 0 ? green["400"] : red["400"]}} align="center">{parseFloat(item.amount) > 0 ? "Vorrätig" : "Nicht vorrätig"}</TableCell>
                          }
                          return <TableCell key={i} align="center">{item[el.id]}</TableCell>
                      })}
                    </TableRow>
                  );
                })}
              {emptyElements > 0 && (
                <TableRow style={{ height: (53) * emptyElements }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={total}
          page={current_page - 1}
          onChangePage={handleChangePage}
          rowsPerPage={elementsPerPage}
          onChangeRowsPerPage={handleChangeElementsPerPage}
        />
      </Paper>
    </div>
  );
}