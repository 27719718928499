import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';



let theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: '#065950',
    // },
    // background: {
    //   default: "#ECEFF1",
    // },
  },
});

theme = responsiveFontSizes(theme);

export default theme;